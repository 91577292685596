.dataSection {
  grid-column: 1/-1;
  .dataList {
    grid-auto-flow: column;
    gap: 12px;
  }
}

.actions {
  grid-column: 1/-1;
}
