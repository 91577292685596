@import "styles/mixins";
.logo,
.sidebarControl {
  grid-area: sidebar_header;
  width: var(--aside-width);
  box-sizing: border-box;
  border-right: 1px solid var(--gl_dividerBorder_secondary);
  background: var(--sidebar_inactive_background);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  & + .sidebar {
    max-width: var(--aside-width);
  }
}

.sidebarControl {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: pointer;
  & > summary {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
    color: var(--gl_icon_constant_secondary);
    list-style: none;
  }

  &[open] {
    & > summary {
      transform: scale(-1, 1);
    }

    & + .sidebar {
      max-width: var(--aside-width);
    }
  }
}

.sidebar {
  background: var(--sidebar_inactive_background);
  color: var(--gl_text_constant_primary2);
  grid-area: sidebar;
  overflow: hidden;
  max-width: 0;
  display: grid;
  grid-template-rows: auto min-content;
  grid-template-columns: 1fr;
  border-right: 1px solid var(--gl_dividerBorder_secondary);
  height: calc(var(--win-height) - var(--app-header-height));
  transition: max-width 0.3s;
}

.item {
  height: var(--nav-cell-height);
  font: var(--aside-name-font);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.title {
  color: var(--gl_text_constant_primary2);
  text-align: center;
}

.iconBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
}

.icon {
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;

  path,
  rect {
    fill: var(--gl_icon_constant_secondary);
  }
}

.active {
  background-color: var(--sidebar_active_background);

  .icon {
    path,
    rect {
      fill: var(--gl_text_constant_primary2);
    }
  }
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.name {
  font: var(--font-10-r);
  color: var(--gl_text_constant_primary2);

  flex: none;
  text-align: center;
  padding: 0 5px;
  overflow: hidden;
  width: var(--aside-width);
  box-sizing: border-box;
  margin-bottom: 0;
}

.userImg {
  width: var(--user-pic-side);
  height: var(--user-pic-side);
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.logout {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid var(--gl_icon_constant_secondary);
  flex: none;
  svg {
    color: var(--gl_icon_constant_secondary);
  }
}

.version {
  position: absolute;
  right: 5px;
  bottom: 2px;
  color: var(--cadet-blue);
  font-size: 0.7em;
}

.dateTime {
  display: grid;
  justify-items: center;
  font: var(--aside-date-font);
  color: var(--sidebar-color, var(--boulder));

  &::before {
    content: attr(datetime);
    font: var(--aside-time-font);
    color: var(--sidebar-color, var(--mine-shaft));
  }
}
