.checkbox {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  grid-column-gap: 8px;
  user-select: none;
}

.checkbox input {
  display: none;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.disabled {
  cursor: default;
}

.checkbox input:checked ~ .mark {
  background-color: var(--dodger-blue);
  border: none;
}

.checkbox input:checked ~ .mark:after {
  display: block;
}

.checkbox .mark {
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: var(--border-default);
  cursor: pointer;
  &.mark-disabled {
    background-color: var(--disabled) !important;
    border: var(--border-default) !important;
    &:after {
      border: solid var(--dodger-blue);
      border-width: 0 1.5px 1.5px 0;
    }
  }
}

.checkbox .mark:after {
  content: "";
  display: none;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  margin-top: -2px;
  margin-left: 1px;
  transform: rotate(45deg);
  cursor: pointer;
}

.checkbox span {
  color: var(--tundora);
  font: var(--font-14-r);
}

.checkbox > .disabled {
  color: var(--silver-chalice);
}

.checkbox .text-tiny {
  cursor: pointer;
}
