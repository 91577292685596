.container {
  user-select: none;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 90px 1fr;
  align-items: center;
  grid-gap: 15px;
}

.timeline {
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &::-webkit-slider-runnable-track{
    width: 100%;
    height: 100%;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    //width: 5px;
    //height: 5px;
    border-radius: 50%;
    background: var(--timeSliderPrts_constant_knob_background);
    margin-top: -12px;
    box-shadow: var(--shadow-sign);
    position: relative;
    cursor: pointer;
    outline: none;
    transition: .3s ease-in-out;
  }
}

.time {
  display: block;
  height: 20px;
  margin-bottom: 5px;
  position: relative;
  container: timeoptions / inline-size;

  @container timeoptions (max-width: 850px) {
    .label{
      font: var(--font-11-r);
    }

    .option .labelUneven{
      opacity: 0;
    }
  }
}


.option {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--timeSliderPrts_text);
  text-align: center;

  .label {
    opacity: 0;
    font: var(--font-13-r);
  }

  .labelShow{
    opacity: 1;
  }

  .shiftName{
    position: absolute;
    top: 38px;
    left: 0;
    font: var(--font-11-r);
    color: var(--gl_text_constant_secondary_disabled2);
    white-space: nowrap;
    transform: translateX(-50%);
  }
}

.button {
  height: 40px;
  border-radius: 2px;
}

.range {
  position: relative;
}

.bar {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 5px;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: var(--timeSliderPrts_slider_inactive_background);
  cursor: pointer;
}

.fill {
  display: block;
  width: 0;
  height: 100%;
  background-color: var(--timeSliderPrts_slider_active_background);
  position: relative;
  z-index: 2;
}

.blackOut{
  display: block;
  position: absolute;
  z-index: 3;
  height: 6px;
  background: linear-gradient(
                  180deg,
                  var(--bottomBar_background_1) 0%,
                  var(--bottomBar_background_1) 25%,
                  var(--timeSliderPrts_slider_inactive_background) 25%,
                  var(--timeSliderPrts_slider_inactive_background) 75%,
                  var(--bottomBar_background_1) 75%,
                  var(--bottomBar_background_1) 100%
  );
  top: 0;
  &:before, &:after{
    content: '';
    display: block;
    height: 12px;
    width: 3px;
    position: absolute;
    transform: translateY(-25%);
  }

  &:before{
    top: 0;
    left: 0;
    background: linear-gradient(
                    90deg,
                    var(--timeSliderPrts_slider_active_background) 0%,
                    var(--timeSliderPrts_slider_active_background) 50%,
                    var(--bottomBar_background_1) 50%,
                    var(--bottomBar_background_1) 100%
    );
  }

  &:after{
    right: 0;
    top: 0;
    background: linear-gradient(
                    90deg,
                    var(--timeSliderPrts_slider_inactive_background) 0%,
                    var(--timeSliderPrts_slider_inactive_background) 50%,
                    var(--bottomBar_background_1) 50%,
                    var(--bottomBar_background_1) 100%
    );
  }
}

.currentTime {
  background-color: var(--timeSliderPrts_timeLablel_background);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--gl_text_constant_primary2);
  font: var(--font-14-r);
  position: absolute;
  left: 0;
  top: -46px;
  min-width: 40px;
  height: 26px;
  z-index: 3;
  padding: 1px 4px;

  &.disabled{
    background-color: var(--tabs_disabled_content);
  }

  & .name{
    font: var(--font-11-r);
    opacity: 0.7;
    white-space: nowrap;
  }
}
