.hallSelect {
  display: grid;
  grid-template-columns: 1fr min-content;
  column-gap: 12px;
}
.inactiveOption {
  pointer-events: none;
  color: var(--gl_text_secondary_2);
  z-index: 1000;
  position: relative;
}
