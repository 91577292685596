#modal {
  .changeStatus {
    --pad-left: 20px;
    color: var(--gl_text_inverse);
    background: var(--gl_background_3);
    box-shadow: var(--shadow-lightest);
    border-radius: 3px;
    width: 700px;
    min-height: 250px;
    max-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .modalTitle {
      color: var(--gl_text_inverse);
    }

    .content {
      display: flex;
      padding: 24px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      max-height: 700px;
      overflow-y: auto;
      scrollbar-gutter: stable;
      @media (max-width: 1366px) {
        padding: 24px 0px 24px 16px;
      }

      .status-labeled {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 4px;
        color: var(--gl_text_inverse);
        font: var(--font-14-r);
        input {
          display: flex;
          height: 31px;
          padding: 0px 8px;
          align-items: center;
          align-self: stretch;
          border-radius: 3px;
          border: 1px solid var(--gl_dividerBorder_primary);
          color: var(--gl_text_inverse);
          background-color: var(--select_default_background_2);
        }
        .invalid {
          border-color: var(--gl_icon_constant_danger);
        }
        @media (max-width: 1366px) {
          width: 432px;
        }
      }

      .hide {
        display: none;
      }

      .error {
        width: 100%;
        color: var(--gl_text_constant_danger);
      }
    }

    .palette {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 9px;
      align-self: stretch;
      flex-wrap: wrap;

      @media (max-width: 1366px) {
        width: 432px;
        gap: 8px;
      }

      .palette-title {
        display: flex;
        width: 668px;
        justify-content: space-between;
        align-items: center;
        button {
          font: var(--font-12-b);
          color: var(--textButton_default_text);
        }
        @media (max-width: 1366px) {
          width: 432px;
        }
      }

      input {
        display: none;
      }

      .color-option {
        width: 47px;
        height: 31px;
        border-radius: 3px;
      }

      input:checked + .color-option {
        outline: 2px solid var(--checkbox_active_background);
        outline-offset: 3px;
        @media (max-width: 1366px) {
          outline-offset: 2px;
        }
      }
    }

    .modalControls {
      box-sizing: border-box;
      height: 76px;
      padding: 24px 12px;
      background-color: var(--bottomBar_background_2);
      gap: 4px;
      display: flex;
      align-items: center;
      button {
        height: var(--height-a);
      }
    }

    @keyframes anim-error {
      from {
        transform: translate(0px, -15px);
        opacity: 0;
        margin-top: 0px;
        height: 0px;
      }
      to {
        transform: translate(0px, 0px);
        opacity: 1;
        margin-top: 8px;
        height: 16px;
        @media (max-width: 1366px) {
          height: 14px;
        }
      }
    }

    @media (max-width: 1366px) {
      --pad-left: 10px;
      min-height: 200px;
      max-height: 550px;
      width: 464px;
      .content {
        max-height: 450px;
      }
    }
  }
}
