.container{
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--gl_background_3);
  color: var(--gl_text_inverse);

  @media(max-width: 1366px) {
    height: 170px;
    width: 440px;

    .form-controls {
      height: 64px;
    }
  }
}

.content{
  justify-content: center;
}

.buttonBlock{
  height: 80px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  box-sizing: border-box;
}
