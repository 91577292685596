@import "styles/mixins";

.stats{
  display: inline-flex;
  align-items: center;

  gap: 13px;
  margin-left: 13px;

  color: var(--gray);

}


.section{
  display: flex;
  align-items: center;
  gap: 5px;
}

.small{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
}

@include forDarkTheme{
  .stats{
    color: var(--silver-chalice);
  }
}
