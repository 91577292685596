@import "styles/mixins";

.dialogList {
  border-radius: 0;

  .filterButton {
    position: relative;
    padding: 6px 2px;

    .counter {
      position: absolute;
      transform: translate(12px, -12px);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: var(--badge_constant_background);
      color: var(--badge_constant_content);
      font: var(--font-12-b);
    }
  }

  .dialogCard {
    min-height: 76px;
    display: grid;
    grid-template-columns: auto;
    padding-left: 12px;
    align-items: center;
    border-bottom: 1px solid;
    border-color: var(--gl_dividerBorder_primary_2);

    &:hover,
    &:has(a.active) {
      background-color: var(--guestsItemlist_hoverActive_background);
    }
    &:has(a.pending) {
      background-color: var(--guestsItemlist_hoverActive_background);
      opacity: 0.5;
    }

    .navLink {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 16px 0px;
      grid-area: 1 / 1;
      color: var(--gl_text_inverse);

      p {
        &:first-of-type {
          min-height: 20px;
        }

        font: var(--font-14-r);
        margin: 0;
        display: flex;
        align-items: center;

        span {
          display: inline-flex;
          text-wrap: nowrap;
        }

        .closedBadge {
          display: inline-flex;
          height: 20px;
          border-radius: 20px;
          padding: 0px 10px;
          justify-content: center;
          align-items: center;
          background-color: var(--tags_constant_background_5);
          color: var(--gl_text_constant_primary2);
        }

        .fullName {
          font: var(--font-14-b);
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-wrap: break-word;
          word-break: break-all;
          --client-info-button-offset: 46px;
          --unanswered-badge-offset: 0px;
          --linked-badge-offset: 0px;
          max-width: calc(
            100% - var(--client-info-button-offset) - var(
                --unanswered-badge-offset
              ) - var(--linked-badge-offset)
          );
          text-wrap: initial;

          &.withUnansweredBadge {
            --unanswered-badge-offset: 28px;
          }

          &.withLinkedBadge {
            --linked-badge-offset: 48px;
          }
        }
        .restaurantName {
          display: -webkit-inline-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-wrap: break-word;
          word-break: break-all;
          max-width: 60%;
          text-wrap: initial;
        }

        .badges {
          display: inline-flex;
          gap: 4px;
          margin-left: 8px;

          .badge {
            display: inline-flex;
            justify-content: center;
            place-items: center;
            font: var(--font-12-r);
            font-weight: 700;
            height: 20px;
            border-radius: 20px;
            color: var(--badge_constant_content);

            &.unansweredCounter {
              width: 20px;
              background-color: var(--badge_constant_background);
            }

            &.linkedCounter {
              background-color: var(--gl_status_dessert_text);
              box-sizing: border-box;
              gap: 2px;
              padding: 0px 6px 0px 2px;
            }
          }
        }
      }
      .tags {
        padding-right: 40px;
      }
    }

    .clientInfo {
      --icon-color: var(--gl_icon_constant_secondary);
      grid-area: 1 / 1;
      padding: 0px 14px 0px 0px;
      justify-self: self-end;
    }
  }
}

.modalPortal {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .modal {
      min-height: 100%;
      background-color: var(--gl_background_2);
      width: 400px;
      z-index: 2;
      font-family: Roboto, sans-serif;

      .modalContent {
        padding: 16px 12px;
        margin: 0px;
      }
    }
  }

  &:empty {
    display: none;
  }
}
