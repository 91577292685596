.boldText {
  font: var(--font-12-r);
}

.greatBoldText {
  font: var(--font-12-r);

  &:disabled {
    color: #ababab;
  }
}

.disabled {
  opacity: 0.7;
}

.radioButtons {
  display: grid;
  grid-auto-flow: column;
}

.guestInfo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  align-items: end;

  @media (min-width: 744px) and (max-width: 1185px) {
    grid-template-columns: 1fr 1fr;
  }
}

.guestInfo > :last-child {
  @media (min-width: 744px) and (max-width: 1185px) {
    grid-column: 1 / -1;
  }
}

.clientIcon {
  margin-left: 5px;
  display: flex;
  align-items: center;

  path {
    fill: var(--gl_icon_constant_secondary);
  }
}

.chipsWrapper {
  display: grid;
  font-family: Roboto, sans-serif;
  width: 245px;
  grid-template-columns: 95px auto auto;
  gap: 4px;
  align-items: flex-end;

  @media (min-width: 744px) and (max-width: 1185px) {
    width: 100%;
    grid-template-columns: repeat(3, auto);
  }

  .chip {
    font: var(--font-12-r);
    text-align: center;
    background: var(--formButton_disabled_background);
    color: var(--gl_text_secondary_2);
    padding: 8px 20px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 3px;
    text-overflow: ellipsis;
  }

  .active {
    background: var(--formButton_active_background);
    color: var(--gl_text_constant_primary2);
  }
}

.chipsWrapperEdit {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 4px;
  align-items: flex-end;

  @media (min-width: 744px) and (max-width: 1185px) {
    width: 100%;
    grid-template-columns: repeat(3, auto);
  }

  .chip {
    font: var(--font-12-r);
    text-align: center;
    background: var(--formButton_disabled_background);
    color: var(--gl_text_secondary_2);
    padding: 8px 20px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 3px;
    text-overflow: ellipsis;
  }

  .active {
    background: var(--formButton_active_background);
    color: var(--gl_text_constant_primary2);
  }
}

.timeSelect {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 6px;

  .selectBox {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-top: 11px;

    .selectBoxItem {
      width: 66px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .inactive {
      background-color: var(--disabled);
      color: var(--gl_text_secondary_2);
    }

    .active {
      background-color: var(--gl_background_accent_2);
      color: var(--gl_text_inverse);
    }
  }
}

.hoursMins {
  display: grid;
  column-gap: 4px;
  grid-template-columns: repeat(2, auto);

  @media (min-width: 744px) and (max-width: 1185px) {
    grid-template-columns: repeat(2, auto);
  }
}

.hoursMinsEdit {
  display: grid;
  column-gap: 4px;
  grid-template-columns: repeat(2, auto);
}

.infinity {
  border: 1px solid var(--gl_dividerBorder_primary) !important;
}

.twoElems {
  display: grid;
  grid-template-columns: 30px auto;
  gap: 8px;

  svg {
    display: block;
  }
}

.oneElement {
  display: grid;
  grid-template-columns: auto;

  svg {
    display: none;
  }
}

.openScheme {
  svg {
    color: var(--columnButton_active_background);
    margin-left: 0;
  }

  &:disabled {
    svg {
      opacity: 0.7;
    }
  }
}

.input {
  border-color: var(--gl_dividerBorder_primary);
  color: var(--gl_text_secondary_disabled);
  cursor: not-allowed;
  -webkit-text-fill-color: var(--gl_text_secondary_disabled);
  -webkit-opacity: 1;
}

.optionLabel {
  display: inline-flex;
  font: var(--font-12-r);
  gap: 10px;
  .optionTitle {
    &.busy {
      color: var(--gl_text_constant_danger);
    }
  }
}
