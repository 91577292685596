.header {
    border: 1px solid var(--gl_dividerBorder_primary);
}

.tagGroupName {
    font: var(--font-20-b);
}

.icon {
    color: var(--gl_icon_inverse);
}