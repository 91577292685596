@import "styles/mixins";

.slotCard {
  display: flex;
  font: var(--font-15-b);
  color: var(--gl_text_inverse);

  &.canceled,
  &.closed,
  &.not_come,
  &.wait_list {
    --status-color-border: var(--newCardBook_other_border);
    --visit-info-bg: var(--newCardBook_other_background);
    --timer-color: var(--newCardBook_other_timer);
  }

  &.wait_list {
    --status-color: var(--status_constant_wait_list_background);
  }

  &.not_come {
    --status-color: var(--status_constant_noshow_background);
  }

  &.closed {
    --status-color: var(--status_constant_completed_background);
  }

  &.canceled {
    --status-color: var(--status_constant_cancelled_background);
  }

  &.confirmed {
    --status-color-border: var(--newCardBook_confirmed_border);
    --status-color: var(--status_constant_confirmed_background);
    --visit-info-bg: var(--newCardBook_confirmed_background);
    --timer-color: var(--newCardBook_confirmed_timer);
  }

  &.in_hall {
    --status-color-border: var(--newCardBook_arrived_border);
    --status-color: var(--status_constant_arrived_background);
    --visit-info-bg: var(--newCardBook_arrived_background);
    --timer-color: var(--newCardBook_arrived_timer);
  }

  &.late {
    --status-color-border: var(--newCardBook_late_border);
    --status-color: var(--status_constant_late_background);
    --visit-info-bg: var(--newCardBook_late_background);
    --timer-color: var(--newCardBook_late_timer);
  }

  &.outstayed {
    --status-color-border: var(--newCardBook_overstayed_border);
    --status-color: var(--status_constant_overstayed_background);
    --visit-info-bg: var(--newCardBook_overstayed_background);
    --timer-color: var(--newCardBook_overstayed_timer);
  }

  &.management {
    --status-color-border: var(--newCardBook_main_border);
    --status-color: var(--status_constant_manager_background);
    --visit-info-bg: var(--newCardBook_main_background);
  }

  border: 2px solid var(--status-color-border, var(--newCardBook_booked_border));
  border-radius: 3px;

  &.hallCard {
    cursor: pointer;
    box-shadow: 0 0 0 0
      rgba(
        from var(--status-color-border, var(--newCardBook_booked_border)) r g b /
          0
      )
      inset;
    transition: box-shadow 0.2s;
    transition-delay: 0s;

    &:active {
      transition: box-shadow 0.3s;
      transition-delay: 0.2s;
      box-shadow: 0 0 15px 5px
        rgba(
          from var(--status-color-border, var(--newCardBook_booked_border)) r g
            b / 0.75
        )
        inset;
    }

    &.dragging {
      opacity: 0.5;
    }
  }

  .cardWrapper {
    width: 100%;
    height: 100%;
    padding: 0px 12px 8px 12px;
    row-gap: 4px;
    display: grid;
    color: var(--gl_text_inverse);
    grid-template-areas:
      "header"
      "content"
      "footer";
    place-content: stretch;
    //background: var(--newCardBook_background_1);
    --status-color: var(--status_constant_notconfirmed_background);

    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */

    &.active {
      --card-border: var(--border-2-solid-blue);
      --card-padding: calc(var(--card-pt) - 1px) calc(var(--card-pr) - 1px)
        calc(var(--card-pb) - 1px) calc(var(--card-pl) - 1px);
    }

    .header {
      grid-area: header;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      align-items: center;
      gap: 10px;
      svg {
        color: var(--gray);
      }
    }

    .content {
      grid-area: content;
      display: grid;
      row-gap: 2px;

      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-width: 0;

        hgroup {
          display: flex;
          min-width: 0;
          column-gap: 6px;
          .cardName {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: var(--gl_text_inverse);
            font: var(--font-18-b);
            margin: 0;
          }
        }

        .cardPhone {
          display: flex;
          column-gap: 10px;
          align-items: center;
          margin: 0;
        }
      }
    }

    .footer {
      grid-area: footer;
      display: grid;
      grid-template-columns: auto 1fr;
      border-radius: 8px;
      column-gap: 8px;
      background: var(--visit-info-bg, var(--newCardBook_booked_background));
      section {
        display: inline-flex;
        padding: 4px;
        min-width: 0;
        figure {
          --icon-color: var(--gl_text_inverse);

          &.time {
            color: var(--timer-color);
          }

          &.tablesContainer {
            min-width: 36px;
            flex-grow: 1;
            max-width: max-content;
            .tables {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          &:last-of-type {
            min-width: 0;
            max-width: max-content;
            flex: 1;
            .comment {
              font: var(--font-12-r);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          figcaption {
            text-wrap: nowrap;
            flex: 1;

            &.hallName {
              font: var(--font-12-r);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        &.primaryInfo {
          column-gap: 12px;
          & > div {
            display: inline-flex;
            column-gap: 5px;
          }
        }
        &.secondaryInfo {
          column-gap: 8px;
        }
      }

      .warningHint {
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 100%;
        grid-column: 1 / 3;
        background: var(--gl_background_attention);
        color: var(--gl_text_constant_primary2);
        font: var(--font-12-r);
        padding: 5px 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .actions {
    display: grid;
    align-items: center;
    border-left: 1px solid var(--gl_dividerBorder_primary);
    padding-right: 1px;
    min-width: 52px;
    width: 52px;
    row-gap: 1px;
    --icon-color: var(--gl_icon_constant_secondary);
    & > div > button {
      height: 100%;
      width: 100%;
    }

    & > div:not(:first-child) > button {
      border-top: 1px solid var(--gl_dividerBorder_primary);
    }
  }
}

.statusGroup {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
  svg {
    color: var(--gray);
  }
  .statusTag {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    flex-shrink: 10;
    align-items: center;

    &.managementTag {
      background-color: var(--status_constant_manager_background);
    }

    &.fromManagementTag {
      background-color: var(--status_constant_notconfirmed_background);
    }
  }

  .baseStatusTag {
    min-width: max-content;
  }
}
