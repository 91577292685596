.title{
  padding-left: 10px;
  color: var(--gl_text_inverse)

}

.searchBlock{
  display: grid;
  grid-template-columns: 180px 1fr 50px 105px 105px 221px;
  grid-gap: 5px;
  padding: 5px;
  margin-right: 5px;
  margin-left: 5px;
  &__order{
    grid-template-columns: 180px 1fr 80px 120px 120px;
  }
}

.list{
  display: grid;
  margin-right: 10px;
  margin-left: 10px;
}

.listItem{
  display: grid;
  grid-template-columns: repeat(6, 1fr) 70px 2fr;
  background: var(--gl_background_2);
  align-self: center;
  grid-gap: 3px;
  padding: 15px 3px;
  margin-bottom: 14px;
  font: var(--font-14-r);
  border: 1px solid var(--gl_dividerBorder_primary_2);
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 #51565F26;
  cursor: pointer;
  & span:last-child{
    justify-self: end;
  }
  & span{
    display: flex;
    align-items: center;
    color: var(--gl_text_inverse);
    font: var(--font-14-r);

    & svg {
      color: var(--gl_icon_primary_1);
    }

    & :first-child{
      margin-right: 5px;
    }

    & strong {
      font: var(--font-16-b);
    }
  }
}

.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
}
