.deposit{
  flex: 1;
  display: flex;
  gap: 10px;
}

.input{
  flex: 1 0;
  min-width: 80px;
}

.inputNew {
  min-width: 80px;
}


.newDeposit {
  display: grid !important;
  grid-template-columns: auto auto auto;
  justify-content: start;

  & > div {
    width: initial !important;
    & > button {
      max-width: 107px;
    }
  }

  .text{
    display: flex;
    align-items: center;
    color: var(--gl_text_inverse);
  }
}