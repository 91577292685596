.tag {
  display: grid;
  padding: 2.5px 10px;
  border-radius: 20px;
  color: var(--gl_text_content_primary);
  font: var(--font-12-b);
}

.tagCount {
  font: var(--font-12-r);
  color: var(--gl_text_content_primary);
  background-color: var(--tags_constant_background_4);
  margin-left: 5px;
  padding: 0 10px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0;
  & > * {
    font: var(--font-12-r);
  }
}
