.tableNumberBody{
  grid-area: 1 / 1;
  height: 20px;
  pointer-events: none;
  background: transparent;
  overflow: unset;
  display: flex;
  justify-content: center;
}

.tableNumber{
  display: flex;
  pointer-events: none;
  margin: 0;
  align-items: center;
  justify-content: center;
  gap: 3px;
  box-sizing: border-box;
  padding: 0 8px;
  border-radius: 15px;
  background-color: var(--count-bg);
  color: var(--count-color);
  font: var(--font-14-b);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}
