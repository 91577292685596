.depositField {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(100%, max(150px, 30%)), 1fr)
  );

  legend {
    margin-bottom: 8px;
    font: var(--font-12-r);
  }

  .useDeposit {
    font-weight: 400;
    transition: background-color 0.3s;
  }
}

.checkbox {
  display: grid;
  font: var(--font-14-r);
  user-select: none;
  column-gap: 7px;
  justify-content: flex-start;
  grid-template-areas: "checkbox text";
  place-items: center;
  color: var(--gl_text_inverse);
  cursor: pointer;
  &::before {
    content: "";
    grid-area: checkbox;
    height: 24px;
    aspect-ratio: 1;
    background-color: var(--checkbox_default_background);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid var(--gl_dividerBorder_primary);
    transition: background-color 0.3s;
  }
  &::after {
    content: "";
    grid-area: checkbox;
    display: block;
    width: 6px;
    height: 12.5px;
    margin-bottom: 2px;
    border: solid var(--checkbox_default_background);
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
    transition: border-color 0.3s;
  }
}

input:disabled + .checkbox {
  cursor: default;
  color: var(--silver-chalice);
  opacity: 0.7;

  &::before {
    background-color: var(--checkbox_disabled_background);
    border-color: var(--gl_dividerBorder_primary);
  }
}

input:checked + .checkbox {
  &::before {
    background-color: var(--checkbox_active_background);
    border: none;
  }
  &::after {
    border-color: var(--checkbox_constant_active_content);
  }
}
