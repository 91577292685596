@import "~react-datepicker/dist/react-datepicker.css";


.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 100000;
}

.react-datepicker {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  border: 1px solid var(--gl_dividerBorder_primary_2);
  box-shadow: var(--shadow-lightest);
  background: var(--dtpkr_background_1);

  .react-datepicker__header{
    padding-top: 5px;
    padding-bottom: 0;
  }

  .react-datepicker__day:hover {
    background: var(--dtpkr_slot_hover_background);
    border-radius: 50%;
    color: var(--gl_text_inverse);

    &.react-datepicker__day--disabled {
      background: transparent;
      border-radius: 50%;
      color: var(--gl_text_secondary_disabled);
    }
  }

  .react-datepicker__day--today{
    color: var(--gl_text_inverse);
    border: 1px solid var(--dtpkr_slot_active_background);
  }

  .react-datepicker__day--keyboard-selected {
    color: var(--gl_text_inverse);
    background-color: transparent;
  }

  .react-datepicker__day--selected {
    background: var(--dtpkr_slot_active_background);
    border-radius: 50%;
    color: var(--gl_text_constant_primary2);
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__day.react-datepicker__day--outside-month {
    color: var(--gl_text_secondary_disabled);
  }

  .react-datepicker__day {
    border-radius: 50%;
    font-weight: 555;
    height: 27px;
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: var(--font-12-b);
    color: var(--gl_text_inverse)
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 15px;
  }

  .react-datepicker__week {
    display: flex;
  }

  .react-datepicker__day-names {
    margin: 15px;
    margin-top: 8px;

    > div {
      text-transform: uppercase;
      color: var(--gl_text_secondary_disabled);
      font: var(--font-12-b);
      font-weight: 555;
    }
  }

  .react-datepicker__header {
    background: var(--dtpkr_background_1);
  }

  .reset-datepicker {
    position: absolute;
    display: flex;
    right: 27px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  border-bottom-color: var(--dtpkr_background_1);
}
