.container{
  width: 216px;
}

.header{
  color: var(--gray);
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;

  font: var(--font-14-r);
}

.cancel{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.hint{
  color: var(--gray);
  font: var(--font-12-r);
  display: block;
}
