.confirmMessage {
  display: grid;
  column-gap: 16px;
  background-color: var(--gl_background_3);
  row-gap: 8px;
  grid-template-columns: 24px auto;
  margin: 0;
  padding: 16px;
  .header {
    margin: 0;
    font: var(--font-16-b);
    color: var(--gl_text_inverse);
  }
  .message {
    margin: 0;
    font: var(--font-14-r);
    color: var(--gl_text_inverse);
  }
  .icon {
    grid-row: span 3 / span 3;
    &.danger {
      color: var(--gl_icon_constant_danger);
    }
    &.warning {
      color: var(--gl_icon_constant_attention);
    }
    &.neutral {
      color: var(--gl_icon_constant_attention);
    }
  }
  .actions {
    padding-top: 12px;
    display: inline-flex;
    color: var(--gl_text_constant_primary2);
    column-gap: 4px;
    place-self: flex-end;
  }
}
