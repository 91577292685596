@import 'styles/mixins';

// TODO REFACTORING //
section.guest-card {
  color: var(--gl_text_inverse);
  font: var(--font-16-b);

  span.phone {
    img {
      margin-right: 5px;
    }
    display: flex;
    align-items: center;
  }

  .datepicker {
    .react-datepicker-wrapper input {
      border: var(--border-2-solid-botticelli);
    }
  }

  --detail-height: 0;
  --grg: 30px;
  --info-row-a-gt: 1fr / repeat(4, min-content);

  --badge-bg: none;
  --badge-shadow: var(--shadow-empty);
  --badge-gta: 'LOY NAM NAM NAM . ' 'PHI PHN TAG TAG .';
  --tags-grid-col: span 1;

  --titled-block-h3-font: var(--font-14-r);
  --titled-block-content-font: var(--font-18-b);
  --name-font: var(--font-26-b);

  --show-bagde: grid;
  --show-avatar-badge: none;
  --show-avatar-detail: block;
  --show-controls-block: flex;
  --show-detail: flex;
  --show-collapse: flex;
  --avatar-detail-side: 245px;
  --detail-padding: 0;
  --card-border: 1px solid var(--gl_dividerBorder_accent);
  --email-phone-direction: row;

  @media (max-width: 1366px) {
    --avatar-detail-side: 120px;
  }

  // Badge grid template columns
  --badge-gtc: 24px minmax(0, 1fr);
  @media (min-width: 950px) {
    --badge-gtc: min-content min-content auto;
  }

  &.no-phone {
    --badge-gta: 'LOY NAM NAM NAM .' 'TAG TAG TAG . . ';
  }

  &.variant-A {
    --avatar-detail-side: 75px;
    @media (max-width: 1366px) {
      --avatar-detail-side: 55px;
    }
    @media only screen and (max-device-width: 1366px) {
      --avatar-detail-side: 40px;
    }
  }

  border-top: var(--card-border);
  border-left: var(--card-border);
  border-right: var(--card-border);

  &.active {
    --badge-shadow: var(--shadow-medium);
  }

  &.selected {
    --badge-bg: var(--selago);
  }

  @media (max-width: 1920px) {
    --info-row-a-gt: 1fr 1fr / 1fr 1fr;
    --grg: 28px;

    &.active {
      --detail-height: 370px;
    }
  }

  @media (max-width: 1366px) {
    --titled-block-h3-font: var(--font-12-r);
    --titled-block-content-font: var(--font-16-b);
    --name-font: var(--font-18-b);
    --grg: 18px;
    --email-phone-direction: column;
    --badge-gta: 'LOY NAM .' 'PHI PHN .' 'TAG TAG TAG';

    &.no-phone {
      --badge-gta: 'LOY NAM .' 'TAG TAG TAG';
    }
    &.active {
      --detail-height: 325px;
    }

    .tag {
      margin-bottom: 7px;
    }
  }
  @media only screen and (max-device-width: 1266px) {
    --titled-block-content-font: var(--font-14-b);
  }

  &:first-of-type {
    border-radius: 3px 3px 0 0;
  }

  &:not(.create):last-of-type {
    border-bottom: var(--card-border);
    border-radius: 0 0 3px 3px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.variant-A {
    --show-bagde: none;
    --detail-height: auto;
    --detail-padding: 5px;
    --card-border: none;
  }

  &.variant-B {
    --show-avatar-detail: none;
    --email-phone-direction: row;
    --show-controls-block: none;
  }

  &.variant-C {
    --show-avatar-badge: block;
    --show-detail: none;
    --show-collapse: none;
  }

  .badge {
    --main-block-top-section-height: 126px;
    display: var(--show-bagde);
    grid-template-columns: min-content auto min-content;
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    align-items: center;
    background: var(--badge-bg);
    box-shadow: var(--badge-shadow);
    transition: var(--animation-duration);
    height: auto;
    min-height: var(--badge-heght);

    .photo {
      display: var(--show-avatar-badge);
      width: 44px;
      height: 44px;
      border-radius: 100%;
      margin-right: 10px;
    }

    .data {
      display: grid;
      grid-template-areas: var(--badge-gta);

      grid-template-columns: var(--badge-gtc);
      align-items: center;
      grid-gap: 10px;

      .star {
        width: 20px;
        grid-row: 3;
      }

      .loyalty-bar {
        grid-area: LOY;
      }
      .call {
        grid-area: PHI;
      }

      .name {
        color: var(--gl_text_inverse);
        margin-bottom: 0;
        font-weight: 700;
        grid-area: NAM;
        overflow-wrap: break-word;
        @media (min-width: 950px) {
          overflow-wrap: normal;
          display: flex;
          gap: 8px;
          align-items: center;
          white-space: nowrap;
        }
      }

      .tags {
        grid-area: TAG;
      }

      .phone {
        grid-area: PHN;
        font: var(--font-14-r);
        word-break: break-word;
        overflow-wrap: break-word;
        @media (min-width: 950px) {
          overflow-wrap: normal;
          white-space: nowrap;
        }
        margin-right: 20px;
      }
      &.no-phone {
        .phone {
          grid-area: auto;
        }
      }
    }

    .collapse {
      display: var(--show-collapse);
      justify-self: flex-end;
    }
  }

  .detail {
    height: var(--detail-height);
    transition: height var(--animation-duration);
    box-sizing: border-box;
    padding: var(--detail-padding) var(--pad);
    overflow: hidden;

    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 10px;
    align-content: center;

    display: var(--show-detail);

    .title-block img {
      width: var(--avatar-detail-side);
      height: var(--avatar-detail-side);
      border-radius: 100%;
      display: var(--show-avatar-detail);
      margin-right: 10px;
    }

    .full-name,
    .birthday {
      margin-bottom: 5px;
    }

    .titled-block {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &.hide {
        display: none;
      }

      h3 {
        margin-bottom: 8px;
        color: var(--gl_text_constant_secondary_disabled2);
        font: var(--font-12-r);
      }

      .content {
        color: var(--gl_text_inverse);
        font: var(--14b);
      }

      &.comment .content {
        white-space: normal;
        word-break: break-all;
        word-break: break-word;
      }
    }

    h2 {
      font: var(--name-font);
    }

    display: grid;
    grid-gap: 10px;
    grid-template: var(--info-row-a-gt);

    .loyalty .content {
      display: grid;
      grid-template-columns: min-content min-content;
      align-items: center;
      grid-column-gap: 10px;
    }

    textarea {
      resize: none;
      height: var(--height-a);
    }

    display: flex;

    & > div:first-of-type {
      margin-right: var(--grg);
    }

    @media (max-width: 1366px) {
      & > div:first-of-type {
        margin-bottom: var(--grg);
        margin-right: 0px;
      }
    }

    flex-direction: var(--email-phone-direction);

    .content {
      white-space: nowrap;
    }

    .controls {
      display: var(--show-controls-block);
      gap: 5px;

      button {
        height: var(--height-a);
        font: var(--titled-block-content-font);
        @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
          font: var(--titled-block-content-font);
        }

        &.secondary {
          margin-left: 10px;
        }
      }
    }
  }

  &.variant-A {
    --pad: 0px;
    // height: calc(100% - 20px);
    flex: 1;
    overflow: auto;

    &.create {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .detail {
        margin-top: 10px;
      }

      .controls {
        background-color: var(--selago);
        height: 64px;
        padding: 0 var(--header-spacing);
        align-items: center;
        display: flex;
        gap: 5px;
        .secondary {
          margin-left: 10px;
        }
      }
    }

    .detail {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .hr {
        border-bottom: 1px solid #dde3ef;
      }

      .title-block {
        margin-bottom: 40px;
        margin-top: 10px;
        padding: 0px 20px;
        margin-right: 0px;
        @media only screen and (max-device-width: 1366px) {
          margin-bottom: 10px;
        }

        display: flex;
        align-items: center;
        flex-direction: column;

        .person-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          margin-bottom: 10px;
          color: var(--gl_text_inverse);

          .photo {
            min-width: var(--avatar-detail-side);
            min-height: var(--avatar-detail-side);
            max-width: var(--avatar-detail-side);
            max-height: var(--avatar-detail-side);
            margin-right: 0;
            margin-bottom: 10px;

            @media (min-width: 950px) {
              margin-right: 20px;
              margin-bottom: 0;
            }
          }

          .full-name {
            overflow-wrap: break-word;
            margin: 0;
            text-align: center;
            width: 100%;

            @media (min-width: 950px) {
              width: initial;
              text-align: initial;
            }
          }

          > div {
            display: grid;
            row-gap: 15px;
            width: 100%;
            grid-template-columns: 100%;

            @media (max-width: 1366px) {
              row-gap: 10px;
            }
          }

          @media (min-width: 950px) {
            flex-direction: row;
            margin-bottom: 0;
          }
        }

        .controls {
          align-items: center;

          button.secondary {
            display: flex;

            &.short {
              display: none;
              padding: 5px;
              img {
                width: 21px;
                height: 21px;
                margin: 0;
              }

              .img.cross-white {
                width: 21px;
                height: 21px;
                margin: 0;
                @include icon-svg-color('cross', #ffffff);
              }
            }

            @media (max-width: 1366px) {
              display: none;

              &.short {
                display: flex;
              }
            }
          }
        }

        @media (min-width: 950px) {
          flex-direction: row;
          justify-content: space-between;
        }
      }

      .data-block {
        padding: 0px 20px;
        margin-bottom: 25px;
        display: grid;
        row-gap: 40px;
        column-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
      }

      &.edit,
      &.create {
        --header-height: 44px;

        > h2 {
          margin: 25px 20px;
          color: #484f54;
          font-size: 18px;
          line-height: 21px;

          @media (max-width: 1366px) {
            margin: 20px 20px;
            font-size: 14px;
            line-height: 16px;
          }
        }

        .hr {
          margin-bottom: 10px;
        }

        > .title-block {
          margin-bottom: 25px;

          @media (max-width: 1366px) {
            margin-bottom: 0px;
            display: block;
          }

          .controls {
            justify-content: center;

            @media (max-width: 1366px) {
              margin: 15px 0px;
            }

            @media (min-width: 950px) {
              justify-content: initial;
            }
          }
        }

        .full-name {
          h3.required:after {
            content: '*';
            font: var(--font-16-b);
            @media (max-width: 1366px) {
              font: var(--font-14-b);
            }
          }
        }

        .data-block {
          width: calc(100% - 40px);
          padding: 0px 20px;
          display: grid;
          row-gap: 25px;
          column-gap: 10px;
          grid-template-columns: repeat(2, minmax(0, 1fr));

          h3 {
            color: var(--gl_text_inverse);
            width: 100%;
          }

          div.comment {
            width: 100%;
            textarea {
              width: 100%;
              height: 100%;
              max-width: 100%;
              max-height: 300px;
              min-height: 130px;
              min-width: 100px;
            }
          }

          .phone {
            grid-area: PHN;
          }
          div.birthday {
            grid-area: BDAY;
          }
          div.registration-time {
            grid-area: REGTIME;
          }
          div.average-visits {
            grid-area: VST;
          }
          div.email {
            grid-area: EML;
          }
          div.tags {
            grid-area: TGS;
            margin-right: 20px;
          }
          div.full-name {
            grid-area: FNM;
          }
          div.comment {
            grid-area: COMN;
          }
          grid-template-areas:
            'FNM FNM FNM FNM FNM FNM'
            'BDAY BDAY BDAY BDAY BDAY BDAY'
            'PHN PHN PHN PHN PHN PHN'
            'EML EML EML EML EML EML'
            'TGS TGS TGS TGS TGS TGS'
            'COMN COMN COMN COMN COMN COMN';

          @media (min-width: 1366px) {
            row-gap: 15px;

            div.comment {
              textarea {
                max-height: 100px;
                min-height: 50px;
              }
            }
          }

          @media (min-width: 950px) {
            grid-template-areas:
              'FNM FNM FNM FNM BDAY BDAY'
              'PHN PHN EML EML COMN COMN'
              'TGS TGS TGS TGS COMN COMN';
          }
        }
      }
    }
  }

  &.variant-B {
    --pad: 20px;

    .badge {
      grid-template-columns: auto;
    }

    .detail {
      flex-direction: column;
      justify-content: center;
      height: var(--detail-height);
      transition: height var(--animation-duration);
      box-sizing: border-box;
      padding: var(--detail-padding) var(--pad);
      display: var(--show-detail);

      overflow-y: scroll;
      @media (min-width: 950px) {
        overflow: hidden;
      }

      .title-block {
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 0;
        margin-right: 0px;

        display: flex;
        flex-direction: column;

        .person-block {
          display: flex;
          align-items: center;

          .photo {
            margin-right: 20px;
          }

          .full-name {
            margin: 0;
          }

          > div {
            display: grid;
            row-gap: 15px;
            grid-row: min-content auto;
          }
        }

        @media (min-width: 950px) {
          flex-direction: row;
          justify-content: space-between;
        }
      }

      .data-block {
        display: grid;
        row-gap: 5px;
        column-gap: 10px;
        margin-bottom: 10px;

        div.phone {
          grid-area: PHN;
        }
        div.birthday {
          grid-area: BDAY;
        }
        div.registration-time {
          grid-area: REGTIME;
        }
        div.average-visits {
          grid-area: VST;
        }
        div.comment {
          grid-area: COMN;
        }
        div.email {
          grid-area: EML;
        }

        grid-template-areas:
          'BDAY'
          'REGTIME'
          'VST'
          'PHN'
          'EML'
          'COMN';

        @media (min-width: 950px) {
          row-gap: 20px;
          grid-template-areas:
            'BDAY REGTIME'
            'VST .'
            'PHN EML'
            'COMN COMN';
        }

        > div {
          min-height: 46px;

          @media (max-width: 1366px) {
            min-height: 41px;
          }
        }
      }
      .history-block {
        display: none;
      }
    }
  }
}

@include forDarkTheme {
  //section.guest-card{
  //  color: var(--wrf-white);
  //}

  section.guest-card .badge .data .loyalty-bar {
    background-color: var(--silver-chalice);
  }

  section.guest-card .badge .data .call path {
    fill: var(--silver-chalice);
  }
}
