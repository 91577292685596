.info {
  width: 360px;
  height: 400px;
  box-sizing: border-box;

  box-shadow: var(--shadow-sign);
  border-radius: 3px;
  cursor: default;

  .sourceContact {
    padding: 12px;
    display: grid;
    color: var(--gl_text_inverse);
    align-content: flex-start;
    align-items: center;
    gap: 12px;
    grid-template-columns: max-content 1fr 1fr max-content;
    grid-template-areas:
      "avatar fullName fullName arrowLink"
      "avatar phone phone arrowLink"
      "data data data data";

    .avatar {
      grid-area: avatar;
      display: block;
      width: 50px;
      aspect-ratio: 1;
      border-radius: 50vmax;
      object-fit: cover;
    }

    .fullName {
      grid-area: fullName;
      font: var(--font-16-b);
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .phone {
      grid-area: phone;
      font: var(--font-14-r);
    }

    .data {
      grid-area: data;
      grid-template-columns: 1fr 1fr;
    }

    .link {
      grid-area: arrowLink;
      display: inline-flex;
      height: min-content;
      aspect-ratio: 1;
      border-radius: 50vmax;
      background-color: var(--gl_icon_constant_primary);
      color: var(--gl_text_constant_primary2);
    }
  }
}
