$primary-color: var(--dodger-blue);
$pagination-item-bg: #fff;
$pagination-item-size: 34px;
$pagination-item-size-sm: 24px;
$pagination-item-bg-active: #fff;
$pagination-item-link-bg: #fff;
$pagination-item-disabled-color-active: #fff;
$pagination-item-disabled-bg-active: #fff;
$pagination-item-input-bg: #fff;

.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
}


:global{
  .pg {
    margin: 0;
    padding: 0;
    font-size: 14px;

    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &::after {
      display: block;
      clear: both;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      content: ' ';
    }

    &-total-text {
      display: inline-block;
      height: $pagination-item-size;
      margin-right: 8px;
      line-height: $pagination-item-size - 2px;
      vertical-align: middle;
    }

    .pg-item,
    .pg-prev,
    .pg-next {
      display: grid;
      place-content: center;
      min-width: $pagination-item-size;
      height: $pagination-item-size;
      margin-right: 8px;
      line-height: $pagination-item-size - 2px;
      text-align: center;
      vertical-align: middle;
      list-style: none;
      background: transparent;
      border: 1px solid var(--gl_dividerBorder_primary_2);
      border-radius: 2px;
      outline: 0;
      cursor: pointer;
      user-select: none;

      a {
        display: block;
        padding: 0 6px;
        color: var(--gl_text_inverse);
        transition: none;
        font: var(--font-14-b);

        &:hover {
          text-decoration: none;
        }
      }

      &:focus,
      &:hover {
        border-color: var(--gl_dividerBorder_hover);
        color: var(--gl_icon_accent);
        transition: all 0.3s;
      }

      &-active {
        border-color: var(--gl_icon_accent);

        a {
          color: var(--gl_text_accent);
        }

        &:focus,
        &:hover {
          border-color: $primary-color;
        }

        &:focus a,
        &:hover a {
          color: $primary-color;
        }
      }
    }

    &-jump-prev,
    &-jump-next {
      outline: 0;

      button {
        background: transparent;
        border: none;
        cursor: pointer;
        color: #666;
      }

      button:after {
        display: block;
        content: '•••';
      }
    }

    &-prev,
    &-jump-prev,
    &-jump-next {
      margin-right: 8px;
    }
    &-prev,
    &-next,
    &-jump-prev,
    &-jump-next {
      display: inline-block;
      min-width: $pagination-item-size;
      height: $pagination-item-size;
      line-height: $pagination-item-size;
      text-align: center;
      vertical-align: middle;
      list-style: none;
      border-radius: 2px;
      cursor: pointer;
      transition: all 0.3s;
      color: var(--gl_icon_inverse);
    }

    &-prev,
    &-next {
      outline: 0;

      button {
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;
        user-select: none;
      }

      &:hover button {
        border-color: $primary-color;
      }

      .pg-item-link {
        display: block;
        width: 100%;
        height: 100%;
        font-size: 12px;
        text-align: center;
        background-color: $pagination-item-link-bg;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        outline: none;
        transition: all 0.3s;
      }

      &:focus .pg-item-link,
      &:hover .pg-item-link {
        color: $primary-color;
        border-color: $primary-color;
      }
    }

    &-prev button:after {
      content: '‹';
      display: block;
    }

    &-next button:after {
      content: '›';
      display: block;
    }

    &-disabled {
      &,
      &:hover,
      &:focus {
        cursor: not-allowed;
        .pg-item-link {
          border-color: #d9d9d9;
          cursor: not-allowed;
        }
      }
    }

    &-slash {
      margin: 0 10px 0 5px;
    }

    &-options {
      display: inline-block;
      margin-left: 16px;
      vertical-align: middle;

      // IE11 css hack. `*::-ms-backdrop,` is a must have
      @media all and (-ms-high-contrast: none) {
        *::-ms-backdrop,
        & {
          vertical-align: top;
        }
      }

      &-size-changer.rc-select {
        display: inline-block;
        width: auto;
        margin-right: 8px;
      }

      &-quick-jumper {
        display: inline-block;
        height: 28px;
        line-height: 28px;
        vertical-align: top;

        input {
          width: 50px;
          margin: 0 8px;
        }
      }
    }

    &-simple &-prev,
    &-simple &-next {
      height: $pagination-item-size-sm;
      line-height: $pagination-item-size-sm;
      vertical-align: top;
      .pg-item-link {
        height: $pagination-item-size-sm;
        background-color: transparent;
        border: 0;
        &::after {
          height: $pagination-item-size-sm;
          line-height: $pagination-item-size-sm;
        }
      }
    }

    &-simple &-simple-pager {
      display: inline-block;
      height: $pagination-item-size-sm;
      margin-right: 8px;

      input {
        box-sizing: border-box;
        height: 100%;
        margin-right: 8px;
        padding: 0 6px;
        text-align: center;
        background-color: $pagination-item-input-bg;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        outline: none;
        transition: border-color 0.3s;

        &:hover {
          border-color: $primary-color;
        }
      }
    }

    // ============================ Disabled ============================
    &-disabled {
      cursor: not-allowed;
      opacity: 0.3;

      &.pg-item,
      &.pg-prev,
      &.pg-next {
        border-color: var(--gl_dividerBorder_primary_2);
        color: var(--gl_icon_inverse);

        cursor: not-allowed;

        &:hover{
          border-color:  var(--ghost);
        }

        a {
          color: var(--ghost);
          background: transparent;
          border: none;
          cursor: not-allowed;
        }

        &-active {
          background: $pagination-item-disabled-bg-active;
          border-color: transparent;
          a {
            color: $pagination-item-disabled-color-active;
          }
        }
      }

      .pg-item-link {
        border-color: #d9d9d9;
        cursor: not-allowed;
      }

      .pg-item-link-icon {
        opacity: 0;
      }

      .pg-item-ellipsis {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .pg-item {
      &-after-jump-prev,
      &-before-jump-next {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .pg-options {
      display: none;
    }
  }//
  // Grid system
  // --------------------------------------------------


  // Container widths
  //
  // Set the container width, and override it for fixed navbars in media queries.

  .container {

  }


  // Fluid container
  //
  // Utilizes the mixin meant for fixed width containers, but without any defined
  // width for fluid, full width layouts.


  // Row
  //
  // Rows contain and clear the floats of your columns.

  .row {

  }
}
