.root {
  :global{
    .bookingHistory{
      height: auto;
    }

    .bookingWrapper{
      padding: 0;
    }
  }
}
