.detailsHeader {
  nav {
    overflow: visible;
  }
}

.detailsCard {
  margin: 16px 12px;
}
.hide {
  display: none;
}
