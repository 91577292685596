.cell {
  cursor: pointer;
}

.cellText {
  color: var(--gl_text_secondary_disabled);
   div a {
    color: var(--gl_text_secondary_disabled);
    pointer-events: none;
   }
}

.eventContent {
  border-radius: 6px;
  background: var(--shiftsSlot_old_default_bg);
  padding: 2px 4px;
  border: none;
  margin: 2px -2px 1px 0;
  box-shadow: none !important;

  &:hover {
    background: var(--shiftsSlot_actualy_default_bg);
  }
}
