.bookingList {
  overflow-y: hidden;
  padding-top: 15px;
  flex: 1;
  max-height: calc(100% - 20px);
  transition: max-height 0.4s ease;

  &.waitList {
    padding: 15px 0 0 0;
    max-height: 0;
  }
}

.counters {
  display: inline-flex;
  align-items: center;
  column-gap: 24px;
  font: var(--font-14-b);
}
