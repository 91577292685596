@import "styles/mixins";

.restaurantControls {
  display: flex;
  gap: 15px;
  > * {
    flex: 1 0;
    max-width: 180px;
  }

  --select_default_background_1: var(--select_default_background_2);
}

.dateControl {
  margin-right: 15px;
  --radioButton_default_background_1: var(--radioButton_default_background_2);
}

.calendar {
  display: flex;
  align-items: center;
  & > * {
    flex: none;
  }
  // Это временные стили :)
  :global(.datepicker) {
    width: 140px;
  }
  --dtpkr_background_1: var(--select_default_background_2);
}

.datepicker {
  @include tablet {
    width: 122px;
  }
}

.datePickerInput {
  font: var(--font-12-r);
}

.dateButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 34px;
  background: var(--pickerArrow_default_background_2);
  border: 1px solid var(--gl_dividerBorder_primary);
  color: var(--gl_icon_constant_secondary);

  @include tablet {
    width: 23px;
  }
  &:first-child {
    border-radius: 3px 0px 0px 3px;
  }

  &:last-child {
    border-radius: 0px 3px 3px 0px;
  }
}

.printBtn {
  background-color: var(--headerButton_secondary_background);
  border: 1px solid var(--gl_dividerBorder_primary);
  color: var(--gl_icon_constant_primary);
  border-radius: 3px;
}

.dialogsReservation {
  font: var(--font-16-b);
  color: var(--gl_text_constant_primary2);
}

.closeButton {
  --icon-color: var(--gl_icon_primary_1);
}

.buttonPlus {
  justify-content: center;
  background-color: var(--gl_status_starters_text);
}
