.bookingCardPreview {
  padding: 16px 12px;
  color: var(--gl_text_inverse);
  font: var(--font-12-b);
  background: var(--newCardBook_background_2);
  border-radius: 3px;
  border-bottom: 1px solid var(--shadow-thick);
  opacity: 0.7;
  left: -30px;
  top: -30px;
}
