@import "styles/mixins";

main {
  #call,
  #tables {
    display: none;
  }

  #call,
  #tables,
  #schedule-landing,
  #hall-scheme > div {
    border-radius: 3px;
    box-shadow: var(--shadow-light);
    background-color: white;
    .block-content {
      max-width: 100%;
      height: var(--normalized-block-content-height);
    }
  }

  #client-card.modal-active {
    position: relative;
  }

  &#create-booking{
    width: calc(100vw - var(--aside-width));
    display: block;

    &.reg {
      --pad: 10px;
      --header-spacing: 10px;

      @media (max-width: 1366px) {
        grid-template-columns: 38% 62%;
      }

      @include tablet {
        grid-template-columns: 100%;
      }
    }

    &.table-booking {
      --pad: 10px;
      --header-spacing: 10px;
      padding: var(--pad);
      display: block;
      grid-template-areas: "tableBook scheme";
      grid-template-columns: 50% 50%;

      @include tablet {
        grid-template-areas: "tableBook";
        grid-template-columns: 100%;
      }
    }

    #table-booking {
      grid-area: tableBook;
    }

  }

  &.create-reg.scheme {
    grid-template-areas: "registration scheme";
  }

  &.create-reg {
    display: block;
  }
}

#content {
  display: flex;
  overflow: hidden;
  height: 100%;
  flex: 1;

  @media(max-height: 800px){
    --user-pic-side: 40px;
    --nav-cell-height: 75px;
  }
}
