.restaurantSelectForm {
  width: max-content;

  .restaurantSelect {
    background-color: transparent;
    border: none;
    padding: 0;
    column-gap: 4px;
    box-shadow: none;
    color: var(--gl_text_constant_primary2);
    height: auto;
    width: auto;

    // стрелка-указатель в виде треугольника
    &::after {
      content: "";
      width: 10px;
      aspect-ratio: 2 / 1;
      background-color: var(--gl_icon_primary_1);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      transition: transform 0.3s;
    }
  }
  .restaurantOptions {
    position: absolute;
    background-color: var(--select_default_background_1);
    border-radius: 3px;

    padding: 0;
    width: 149px;
    scrollbar-gutter: stable;

    ul {
      display: grid;

      grid-template-columns: 1fr;
      li {
        display: contents;
      }
    }

    .restaurantOption {
      display: block;
      padding: 8px;
      cursor: pointer;
      font: var(--font-12-r);
      color: var(--gl_text_inverse);
      align-content: center;
      &:hover {
        background-color: var(--selectMenu_hover_background);
      }
    }

    input:checked + .dateOption {
      background-color: var(--selectMenu_focus_background);
    }
  }

  details[open] {
    .restaurantSelect {
      // псевдоэлемент на весь экран, чтобы закрыть details пли клике за его пределами
      &::before {
        content: "";
        width: 100dvw;
        height: 100dvh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: auto;
      }
      // вращаем стрелку при открытии
      &::after {
        transform: rotate(180deg);
      }
    }
    // высота открытого списка не превышает 220px
    & + .restaurantOptions {
      max-height: min(320px, 30dvh);
      padding: 0;
      overflow-y: auto;
      scrollbar-width: auto;
      border: 1px solid var(--gl_dividerBorder_primary);
    }
  }
}
