@use "sass:math";
$horizontalBasePadding: 9px;
$addonSize: 24px;

.inputContainer {
  font: var(--font-12-r);
  color: var(--gl_text_inverse);
  position: relative;
}

.input {
  border: 1px solid var(--gl_dividerBorder_primary);
  border-radius: 3px;
  background: var(--select_default_background_1);
  color: inherit;
  font: var(--font-12-r);
  box-sizing: border-box;

  width: 100%;

  padding: 8px $horizontalBasePadding 7px;
  transition: all 0.2s;

  & + datalist {
    border: 1px solid var(--gl_dividerBorder_primary);
  }

  &:hover,
  &:hover + datalist {
    border-color: var(--gl_dividerBorder_hover);
  }

  &:active,
  &:focus,
  &:active + datalist,
  &:focus + datalist {
    border-color: var(--gl_dividerBorder_accent);
  }

  &:disabled,
  &:read-only,
  &:disabled + datalist,
  &:read-only + datalist {
    background: var(--select_disabled_background);
    border-color: var(--gl_dividerBorder_primary);
    color: var(--gl_text_secondary_disabled);
    cursor: not-allowed;
    -webkit-text-fill-color: var(
      --gl_text_secondary_disabled
    ); /* Override iOS / Android font color change */
    -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
  }

  &::placeholder {
    color: var(--gl_text_secondary_disabled);
  }

  &.invalid,
  &.invalid + datalist {
    border-color: var(--gl_icon_constant_danger);
  }
}

.addon {
  --icon-color: var(--addon-color, var(--gl_icon_constant_secondary));
  color: var(--icon-color);
  position: absolute;
  width: $addonSize;
  height: $addonSize;
  top: 50%;
  transform: translate(0, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
}

.withPrefix {
  padding-left: $horizontalBasePadding + $addonSize;
}

.withSuffix {
  padding-right: $horizontalBasePadding + $addonSize;
}

.prefix {
  left: math.div($horizontalBasePadding, 2);
}

.suffix {
  right: $horizontalBasePadding;
}

.clearable {
  padding-right: 22px;
}

.clear {
  position: absolute;
  top: 50%;
  right: 2px;
  padding: 2px;
  translate: 0 -50%;
  fill: var(--gl_icon_constant_secondary);

  cursor: pointer;

  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  > svg {
    display: block;
  }
}
