.phoneMenu {
  background: #fff;
  width: 24px;
  height: 32px;
  border: 1px solid #8b8b8b;
  border-radius: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;
  --bar-color: #8b8b8b;

  &:hover {
    --bar-color: #646464;
  }

  .wrapper {
    width: 4px;
  }

  .bar {
    width: 4px;
    height: 4px;
    background-color: var(--bar-color);
    margin: 2px 0;
    border-radius: 50%;
  }
}
