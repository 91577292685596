.FeedList {
  border-radius: 0;
}

.feedCards {
  display: grid;
  gap: 8px;

  .feedCard {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;
    border: 1px solid var(--gl_dividerBorder_primary_2);
    border-radius: 3px;
    background-color: var(--gl_background_2);
    box-shadow: 0px 2px 3px 0px rgba(81, 86, 95, 0.15);
    cursor: pointer;
    &:not(:has(button:hover)):hover {
      background-color: var(--guestsItemlist_hoverActive_background);
    }

    p {
      display: inline-flex;
      margin: 0;
      gap: 8px;
      align-items: center;
      font: var(--font-14-r);
      color: var(--gl_text_inverse);
      --icon-color: var(--gl_icon_primary_1);
    }

    .topContainer {
      display: flex;
      justify-content: space-between;

      .restaurant {
        width: 70%;

        .restaurantName {
          font: var(--font-16-b);
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-wrap: break-word;
          word-break: break-all;
          --diff-width: 36px;
          max-width: calc(100% - var(--diff-width));
          text-wrap: initial;
        }
      }

      .statusName {
        text-wrap: nowrap;
      }
    }

    .bottomContainer {
      display: flex;
      gap: 16px;

      button {
        display: grid;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        margin-left: auto;

        &.unlinked {
          color: var(--gl_icon_constant_secondary);
          background-color: var(--buttonSmall_disabled_background);
        }

        &.linked {
          color: var(--gl_icon_constant_primary2);
          background-color: var(--gl_icon_accent);
        }

        &.loadLinking {
          opacity: 0.5;
        }
      }
    }
  }
}
.bottomBarTablet {
  display: block;
  height: 80px;
}
