.info {
  .header {
    padding: 16px 12px;
    min-height: 77px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr min-content;
    border-bottom: 1px solid var(--gl_dividerBorder_primary);

    .fullName {
      font: var(--font-18-b);
      color: var(--gl_text_inverse);
      column-gap: 8px;
      margin: 0;
    }
  }

  .dataSectionTitle {
    font: var(--font-16-b);
    color: var(--gl_text_secondary_2);
  }
  .dataSection {
    padding: 12px;
    .dataList {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: max-content max-content;
      grid-auto-flow: column;
      column-gap: 12px;
    }
  }
}
