.editPhoto{
  position: absolute;
  top: 25px;
  left: 20px;
  cursor: pointer;
}

.inputFile{
  display: none;
}

.menu{
  padding: 0 10px;
}

.remove{
  color: var(--gl_text_constant_danger) !important;
}
