.form {
  display: grid;
  gap: 12px;
  padding: 12px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(4, max-content) 1fr;
  overflow-y: auto;
  :nth-child(6) {
    grid-row: 3;
  }
  :nth-child(7) {
    grid-row: 4;
  }
  :nth-child(8) {
    grid-row: 4;
  }
  input {
    font: var(--font-12-r);
  }

  .formErrors {
    grid-row: 5;
    grid-column: 1 / -1;
  }
}

.formFooter {
  display: flex;
  padding: 24px 12px;
  column-gap: 4px;
  justify-content: flex-end;
  background-color: var(--bottomBar_background_1);
}
