@import "styles/mixins";

.__bookingPickup {
  background-color: var(--dodger-blue);
  border-radius: 5px;
  color: #fff;
  margin-right: 10px;
  font: var(--font-14-b);
}

button.primary.edit-guest {
  background: var(--dodger-blue) !important;
  padding: 0;
}

button.primary.edit-booking {
  width: 34px !important;
  height: 34px !important;
}

.autoChoice{
  background-color: var(--radioButton_active_background);
  color: var(--gl_text_constant_primary2);
  font: var(--font-12-r);
}
