@import "styles/mixins";

.dataBlock {
  padding: 24px 12px;
  display: grid;
  column-gap: 10px;
  row-gap: 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  align-items: start;
  .contentSection {
    display: grid;
    row-gap: 16px;
    h2 {
      font: var(--font-16-b);
      color: var(--gl_text_secondary_2);
    }
  }

  @include tablet {
    grid-auto-flow: column;
    grid-template-rows: repeat(2, minmax(0, max-content));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.title {
  display: inline-block;
  color: var(--gl_text_secondary_2);
  margin-bottom: 24px;
  font: var(--font-16-b);
}

.titleBlock {
  margin-bottom: 20px;

  h3 {
    font: var(--font-12-r);
    margin-bottom: 4px;
    color: var(--gl_text_constant_secondary_disabled2);
  }
  span {
    color: var(--gl_text_inverse);
    font: var(--font-14-b);
    word-wrap: break-word;
  }
  .empty {
    color: var(--gl_text_secondary_2);
  }
}
