.dialogContainer,
.dialogContainer::backdrop {
  transition:
    500ms opacity,
    500ms transform,
    500ms overlay allow-discrete,
    500ms display allow-discrete;
  opacity: 0;
}

.dialogContainer {
  padding: 0;
  border: none;
  border-radius: 4px;
  overflow: visible;
  transform: translateY(-48px);

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(7.5px);
  }

  &[open],
  &[open]::backdrop {
    opacity: 1;
  }

  &[open] {
    transform: translateY(0);
  }
}

@starting-style {
  .dialogContainer[open],
  .dialogContainer[open]::backdrop {
    opacity: 0;
  }

  .dialogContainer,
  .dialogContainer[open] {
    transform: translateY(48px);
  }
}

.dialogForm {
  background-color: var(--gl_background_3);
  border-radius: 3px;
  border: 1px solid var(--gl_dividerBorder_primary_2);
  min-width: 380px;
}

.closeButton {
  float: inline-end;
  margin-inline: 0.5rem;
  margin-block-start: 0.5rem;
  --icon-color: var(--gl_icon_primary_1);
}
