@import "styles/mixins";

.root {
  height: min-content;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  right: 7px;
  top: calc(50%);
  transform: translateY(-50%);
  gap: 30px;
}

.reset {
  transform: rotate(45deg);
}

.button{
  height: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%);
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
}

@include forDarkTheme {
  .button{
    background: var(--06dp);
    color: var(--silver-chalice);
  }
}
