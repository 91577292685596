@import 'styles/mixins';

#modal {
  --titled-font: var(--font-14-r);

  display: none;

  &.active {
    display: block;
    z-index: 5;
    --z-index-multi: 5;
  }

  position: fixed;
  left: 94px;
  width: calc(100vw - 94px);
  height: calc(100vh - 60px);
  top: 60px;
  background-color: rgba(24, 24, 24, 0.4);
  z-index: 2;

  @include tablet {
    left: 0;
    top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    --titled-font: var(--font-12-r);
  }

  > div {
    width: 100%;
    height: 100%;
  }

  &.changesParams,
  &.confirm,
  &.changeUser,
  &.changeHostess,
  &.status,
  &.info,
  &.error {
    &.active,
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.info,
  &.error {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(24, 24, 24, 0.4);
    z-index: 2;
  }

  &.error {
    z-index: 10;
  }

  &.create {
    > div > img {
      top: 22px;
      left: calc(100vw - 122px - 61px);

      @media (max-width: 1366px) {
        top: 22px;
        left: calc(100vw - 94px - 61px);
      }
    }
    .hall-legend {
      top: 22px;
      left: calc(100vw - 122px - 290px - 91px);

      @media (max-width: 1366px) {
        top: 22px;
        left: calc(100vw - 290px - 81px);
      }
    }
  }

  > div > img {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: calc(100vw - 122px - 51px);

    @media (max-width: 1366px) {
      left: calc(100vw - 51px);
    }
  }
}
