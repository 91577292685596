.button {
  padding: 0 13px;
  border-radius: 5px;
  color: white;
  font: var(--action-button-font);
  height: var(--button-height, var(--height-a));
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition:
    opacity 0.3s ease,
    color 0.3s ease;

  &:not(:disabled):hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }

  &.link:active,
  &.link:hover {
    color: currentColor;
  }
}

.primary {
  background-color: var(--columnButton_active_background);
  border-color: var(--columnButton_active_background);
}
.secondary {
  background-color: var(--columnButton_secondary_background);
  border-color: var(--columnButton_secondary_background);
}
.dangerous {
  background-color: var(--columnButton_constant_danger_background);
  border-color: var(--columnButton_constant_danger_background);
}

.status {
  background-color: var(--columnButton_constant_status_background);
  border-color: var(--columnButton_constant_status_background);
}

.light {
  background-color: var(--btn-light, var(--dove-gray));
  border-color: var(--btn-light, var(--dove-gray));
}

.thin {
  background-color: var(--radioButton_default_background_1);
  border-color: var(--radioButton_default_background_1);
  color: currentColor;
  border: 1px solid var(--gl_dividerBorder_primary);
  font-weight: normal;
}

.phantom {
  display: inline-block;
  color: inherit;
  padding: 0;
  border-radius: unset;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-size: inherit;
}

.square {
  width: var(--button-height, var(--height-a));
  height: auto;
  padding: 0;
  display: flex;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.button:disabled,
.link[aria-disabled="true"],
.link[aria-disabled="true"]:hover {
  cursor: not-allowed;
  color: var(--gl_text_secondary_disabled);
  border: 1px solid var(--gl_dividerBorder_primary);
  background: var(--formButton_disabled_background);

  &:active {
    pointer-events: none;
  }
}
