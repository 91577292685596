.chat {
  border: solid var(--gl_dividerBorder_primary);
  border-width: 0 1px;
  border-radius: 0;
}
.chatContent {
  background-color: var(--newCardBook_booked_background);
}
.headerTabs {
  ::after {
    box-sizing: border-box;
    height: 4px;
    bottom: -13px;
  }

  .addonButtons {
    display: flex;
    gap: 12px;
  }
}
