@import "styles/mixins";

.layout {
  grid-column: 1 / -1;
  display: grid;
  --ideal-size: 590px;
  --max-column-count: 2;
  --gap: 0.5em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: var(--gap);
  overflow: hidden;
  grid-template-rows: max-content 1fr;
  grid-auto-rows: 0;
  .navLinks {
    background-color: var(--title_background_1);
  }
  @include tablet {
    grid-template-columns: 1fr;
  }
}
