.hoursWrapper {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 12px;

    @media (min-width: 744px) and (max-width: 1185px) {
        grid-template-columns: 1fr;
        gap: 16px;
    }

    @media (min-width: 1280px) {
        grid-template-columns: repeat(2, auto);
        gap: 16px;
    }

}

.date {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-self: flex-start;


    button {
        height: 40px;
        font: var(--font-12-r);

        @media (min-width: 1280px) {
            height: 31px;
        }

    }
}

.date div {
    padding: 12px 26px;
    align-self: flex-start;
}

.default {
    background: var(--selectMenu_hover_background);
    color: var(--gl_text_inverse);
    cursor: pointer;
}

.disabled {
    background-color: var(--formButton_disabled_background);
    color: var(--gl_text_secondary_disabled);
    opacity: 0.7;
}

.active {
    background-color: var(--columnButton_active_background);
    color: var(--gl_text_constant_primary2);
    cursor: pointer;
}

.bookingLabel {
    span {
        font: var(--font-12-r);
        margin-bottom: 4px;
    }
}