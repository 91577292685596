.container {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;


    .title {
        font: var(--font-16-b);
        color: var(--gl_text_secondary_2);

        &:not(:first-child) {
            margin-top: 24px;
        }
    }

    .infoBlock {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-height: calc(100% - 120px);

        .line {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &> :nth-child(2) {
                width: 100%;
                border: 1px dotted var(--gl_dividerBorder_primary_2);
                border-bottom: none;
                grid-column: 2 / 3;
                height: 1px;
            }

            .key {
                width: max-content;
                font: var(--font-14-r);
                text-wrap: nowrap;
                color: var(--gl_text_secondary_2);
            }

            .value {
                font: var(--font-14-b);
                color: var(--gl_text_inverse);
                text-align: right;
                text-wrap: nowrap;
                text-transform: capitalize;
            }
        }
    }

    .APIBlock {
        margin-top: 12px;
        display: flex;
        position: relative;

        .copy {
            position: absolute;
            top: -8px;
            right: 0px;
            font: var(--font-12-r);
            color: var(--gl_text_secondary_2);
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            z-index: 9999;
        }

        label {
            width: 100%;

            span {
                margin-bottom: 12px;
            }

            textarea {
                width: 100%;
                height: 320px;
                background-color: var(--select_disabled_background);
                color: var(--gl_text_secondary_disabled);
                resize: none;
            }
        }
    }
}