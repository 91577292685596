.spinner {
  position: absolute;
  z-index: 100;
  width: 100%;
}

.arrow {
  color: var(--gl_icon_inverse);
  display: flex;
  width: 28px;
  height: 28px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  transition: all 0.3s ease;
}
.active {
  background-color: var(--collapseButton_active_background);
  color: var(--gl_icon_accent);
  transform: rotate(180deg);
}
