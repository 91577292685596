.textarea {
  border: 1px solid var(--gl_dividerBorder_primary);
  border-radius: 3px;
  background: var(--select_default_background_1);
  color: var(--gl_text_inverse);
  box-sizing: border-box;
  height: auto;
  transition: height 0.5s;
  width: 100%;

  font-family: inherit;
  font: var(--font-12-r);

  padding: 8px 9px;

  &:active,
  &:focus {
    border-color: var(--gl_dividerBorder_accent);
  }

  &:disabled {
    background: var(--select_disabled_background);
    border-color: var(--gl_dividerBorder_primary);
    color: var(--gl_text_secondary_disabled);
    cursor: not-allowed;
    -webkit-text-fill-color: var(
      --gl_text_secondary_disabled
    ); /* Override iOS / Android font color change */
    -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
  }

  &::placeholder {
    color: var(--gl_text_secondary_disabled);
  }
}

.container {
  display: grid;
  justify-items: end;
  align-items: end;
  .autoResize {
    box-sizing: content-box;
    padding-bottom: 4px;
    width: 100%;
    width: stretch;
    grid-area: 1/1;
    height: auto;
    resize: none;
    font: var(--font-14-r);
    -ms-overflow-style: none;
    scrollbar-width: none;
    &:-webkit-scrollbar {
      display: none;
    }
  }
  .counter {
    margin-right: 12px;
    font: var(--font-12-r);
    color: var(--gl_text_secondary_disabled);
    color: color-mix(
      in oklch shorter hue,
      var(--gl_text_secondary_disabled)
        calc(100% - var(--text-length-percentage)),
      var(--gl_text_constant_danger) var(--text-length-percentage)
    );
    grid-area: 1/1;
    &::after {
      content: "/" attr(value);
    }
  }
}
