.button {
  display: flex;
  align-items: center;
  color: var(--gl_icon_constant_secondary);
  margin-bottom: 18px;

  svg {
    transition: transform 0.3s;
    line {
      transition:
        transform 0.5s,
        stroke-opacity 0.3s;
      transform-origin: center center;
    }
    circle:first-child {
      transform-origin: center center;
      transition: transform 0.3s;
    }
    circle:not(:first-child) {
      transition: fill-opacity 0.3s;
      fill-opacity: 0;
    }
  }

  &.dark {
    svg {
      transform: rotate(-270deg);
      line {
        transform: scale(0);
        stroke-opacity: 0;
      }
      circle:first-child {
        transform: scale(1.2);
      }
      circle:not(:first-child) {
        fill-opacity: 1;
      }
    }
  }
}
