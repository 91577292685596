// Animations
@keyframes phone-corner {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    -webkit-box-shadow: 0 0 0 5px rgba(159, 168, 218, 0.3),
    0 0 0 8px rgba(159, 168, 218, 0.2), 0 0 0 10px rgba(197, 202, 233, 0.1);
    box-shadow: 0 0 0 5px rgba(159, 168, 218, 0.3),
    0 0 0 8px rgba(159, 168, 218, 0.2), 0 0 0 10px rgba(197, 202, 233, 0.1);
  }

  40% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  60% {
    -webkit-box-shadow: 0 0 0 5px rgba(159, 168, 218, 0.3),
    0 0 0 8px rgba(159, 168, 218, 0.2), 0 0 0 10px rgba(197, 202, 233, 0.1);
    box-shadow: 0 0 0 5px rgba(159, 168, 218, 0.3),
    0 0 0 8px rgba(159, 168, 218, 0.2), 0 0 0 10px rgba(197, 202, 233, 0.1);
  }

  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ding-dong {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }
  40% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
  }
}

// Style
.phone-indicator {
  background: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 150px;
  border: #aaa 1px solid;
  margin-right: 4px;
  animation: none;

  &.active {
    animation: phone-corner 1.2s ease infinite;
  }

  .phone-icon {
    background: url("../../assets/img/phone.svg") no-repeat center;
    width: 24px;
    height: 24px;
    margin: 5px 5px;
    animation: none;

    &.active {
      animation: ding-dong 1.2s ease infinite;
    }
  }
}
