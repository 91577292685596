.tabs {
  font-size: 14px;
}

.content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  display: block;
  margin-bottom: 24px;
}

.halfRow {
  width: 50%;
}

.personsInput {
  display: block;
}

.dateBlock {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
  align-items: end;

  &Fixed {
    grid-template-columns: 1fr 1fr 100px;
  }
}


.checkbox {
  padding-left: 8px;
}

.seatCheckbox {
  padding: 8px;
}

.modalFooter {
  justify-content: right;
}

.modalContent {
  overflow-y: scroll;
}

.capacityRow {
  margin-bottom: 16px;
}

.description {
  color: var(--gl_text_constant_secondary_disabled2)
}

.durationsBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 8px;
}

.propertiesCheckbox {
  @extend .checkbox;
  width: min-content;
}

.card {
  overflow: auto;
}
