.form {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    align-items: start;
    justify-content: start;
    grid-auto-rows: min-content;
    box-sizing: border-box;
    overflow-x: hidden;
    grid-auto-rows: min-content;
    box-sizing: border-box;
    overflow: auto;
    overflow-y: auto;
    overflow-x: auto;

    @media (min-width: 744px) and (max-width: 1185px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: min-content;
        grid-template-rows: auto;
        gap: 16px;
    }

    @media (orientation: portrait) {
        grid-template-columns: 1fr ;
        overflow-y: scroll;
        grid-template-rows: min-content;
    }
}

.timePart {
    margin-top: 16px;
    padding: 0px 10px 16px 10px;
    display: grid;
    grid-template-columns: repeat(2, 229px) auto;
    grid-column-gap: 4px;
    border-bottom: 1px solid var(--gl_dividerBorder_primary);

    @media (min-width: 744px) and (max-width: 1185px) {
        height: 100%;
        grid-template-columns: auto;
        grid-auto-rows: min-content;
        border-right: 1px solid var(--gl_dividerBorder_primary);
        border-bottom: none;

        label {
          &:first-child {
            margin-top: 0;
          }

            margin-top: 16px;
        }
    }
}

.timePartFull {
    margin-top: 16px;
    padding: 0px 10px 16px 10px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 4px;
    border-bottom: 1px solid var(--gl_dividerBorder_primary);

    @media (min-width: 744px) and (max-width: 1185px) {
        height: 100%;
        grid-template-columns: auto;
        grid-auto-rows: min-content;
        border-right: 1px solid var(--gl_dividerBorder_primary);
        border-bottom: none;

        label {
            &:first-child {
                margin-top: 0;
            }

            margin-top: 16px;
            grid-column: span 2;
        }
    }

    @media (orientation: portrait) {
        border-right: none;
        border-bottom: none;
    }
}

.mainInfo {
    display: grid;
    padding-top: 16px;
    padding-right: 10px;
    padding-left: 10px;
    row-gap: 16px;

    @media (min-width: 744px) and (max-width: 1185px) {
        grid-auto-rows: min-content;
        height: 100%;
        border-right: 1px solid var(--gl_dividerBorder_primary);
        border-bottom: none;
        padding-right: 12px;
    }

    @media (orientation: portrait) {
        border-top: 1px solid var(--gl_dividerBorder_primary);
        border-right: none;
    }

    >:first-child {
        width: fit-content;

        @media (min-width: 744px) and (max-width: 1185px) {
            width: 100%;
        }
    }

    .duration {
        display: grid;
        grid-template-columns: 65px auto;
        column-gap: 4px;

        > :last-child {
            display: grid;
            column-gap: 4px;
            grid-template-columns: repeat(2, 96px);

            @media (min-width: 744px) and (max-width: 1185px) {
                grid-template-columns: repeat(2, auto)
            }
        }

        @media (min-width: 744px) and (max-width: 1185px) {
            grid-template-columns: 65px auto;
        }
    }

    .placeBox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: end;
        column-gap: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--gl_dividerBorder_primary);

        >:first-child {
            >:last-child {
                display: grid;
                grid-template-columns: auto;
                column-gap: 4px;



                button:disabled {
                    svg {
                        opacity: 0.7;
                    }
                }

                button {
                    display: none;

                    @media (min-width: 744px) and (max-width: 1185px) {
                        display: block;
                    }

                    svg {
                        margin-left: 0;
                    }
                }

                @media (min-width: 744px) and (max-width: 1185px) {
                    grid-template-columns: auto 30px;
                }
            }
        }

        > :last-child {
            > :last-child {
                gap: 4px;
                grid-template-columns: auto !important;

                @media (min-width: 744px) and (max-width: 1185px) {
                    display: grid;
                    grid-template-columns: auto 30px;
                    grid-auto-rows: min-content;
                    border-bottom: none;

                    button svg {
                        margin-left: 0;
                    }
                }
            }
        }

        @media (min-width: 744px) and (max-width: 1185px) {
            grid-template-columns: auto;
            padding-bottom: 0;
            grid-auto-rows: min-content;
            border-bottom: none;
        }

        .tableBox {
            display: grid;
            grid-template-columns: 213px 106px;
            grid-auto-rows: min-content;
            align-items: flex-end;
            column-gap: 4px;

            @media (min-width: 744px) and (max-width: 1185px) {
                display: none;
            }
        }

        .tableBoxEdit {
            display: grid;
            grid-template-columns: repeat(2, auto);
            grid-auto-rows: min-content;
            align-items: flex-end;
            column-gap: 4px;

            @media (min-width: 744px) and (max-width: 1185px) {
                display: none;
            }
        }
    }

    .edit {
        >:first-child {
            >:last-child {
                display: grid;
                grid-template-columns: auto 30px;

                button {
                    display: block;
                }
            }
        }
    }

    .tableBox {
        display: none;

        @media (min-width: 744px) and (max-width: 1185px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: min-content;
            align-items: flex-end;
            column-gap: 4px;
        }
    }
}

.daysOfRePeat {
    padding: 16px 12px 0px 12px;

    @media (min-width: 744px) and (max-width: 1185px) {
        padding-top: 16px;
        padding-right: 20px;
    }

    @media (orientation: portrait) {
        border-top: 1px solid var(--gl_dividerBorder_primary);
    }
}

.showInSmall {
    display: none;

    @media (min-width: 744px) and (max-width: 1185px) {
        display: grid;
        margin-top: 4px;
        grid-column: span 2;
    }
}

.content {
    padding: 10px;
}
