.collapse {
  display: flex;
  flex-direction: column;
}

.header {
  background: var(--collapse_list_inactive_background_2);
  color: var(--gl_text_inverse);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color, color;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease-in;

  &.active {
    background-color: var(--collapse_list_active_background_2);
    border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
    z-index: 99;
  }
}

.chevron__block {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in;
}

.section {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  padding: 5px 0;
}

.icon {
  color: var(--gl_icon_primary_1);
}

.rotate {
  transform: rotate(180deg);
  background: var(--collapseButton_active_background);
  .icon {
    color: var(--gl_icon_accent);
  }
}

.collapse-body {
  overflow: hidden;
  transition: height 0.6s ease;
}

.controlledCollapse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 12px;
  background-color: var(--bottomBar_background_1);
  color: var(--gl_text_inverse);
  cursor: pointer;

  .title {
    font: var(--font-14-b);
  }

  & > em {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }
  .arrow {
    color: var(--gl_icon_inverse);
    display: flex;
    width: 28px;
    height: 28px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    transition: all 0.3s ease;
    margin: 0;
  }

  input:checked ~ em > .arrow {
    background-color: var(--collapseButton_active_background);
    color: var(--gl_icon_accent);
    transform: rotate(180deg);
  }
}

.content {
  max-height: 0;
  transition: max-height 0.4s;
  &.open {
    max-height: 100%;
    padding-top: 6px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 5px;
    &:-webkit-scrollbar {
      display: none;
    }
  }
}

.detailsCollapse {
  summary {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0px 24px;
    border-radius: 3px;
    background-color: var(--bottomBar_background_1);
    color: var(--gl_text_inverse);
    border: 1px solid var(--gl_dividerBorder_primary);
    border-bottom-width: 0;
    list-style: none;
    & > dfn {
      font: var(--font-14-b);
    }
    .arrow {
      color: var(--gl_icon_inverse);
      display: flex;
      width: 28px;
      height: 28px;
      padding: 2px;
      justify-content: center;
      align-items: center;
      border-radius: 60px;
      transition:
        transform 0.3s ease,
        color 0.3s ease,
        background-color 0.3s ease;
      margin: 0;
    }
  }

  & + section.content {
    box-sizing: border-box;
    max-height: 0;
    overflow: hidden;
    padding: 0 10px;
    transition: max-height 0.3s ease-in-out;
  }

  &[open] {
    summary {
      box-shadow: 0px 2px 3px 0px #51565f26;

      .arrow {
        background-color: var(--collapseButton_active_background);
        color: var(--gl_icon_accent);
        transform: rotate(180deg);
      }
    }

    & + section.content {
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      padding-bottom: 5px;
      max-height: 100vh;
      //max-height: calc-size(auto);
      &:-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.collapses {
  display: grid;
  overflow-y: auto;
  & > li {
    display: grid;
  }
}

.list {
  display: grid;
  padding: 16px 18px;
  row-gap: 6px;
}

.item {
  min-height: 55px;
  padding-inline: 18px;
  border: 1px solid var(--gl_dividerBorder_primary);
  color: var(--gl_text_inverse);
  box-shadow: 0px 2px 3px 0px #51565f26;
  border-radius: 3px;
}
