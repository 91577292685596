@import "styles/mixins";

.container{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 1;
  @include tablet{
    grid-template-columns: 1fr;
  }
}

.buttons{
  @include tablet{
    align-items: stretch;
    flex-direction: column;
  }
}
