.arrow {
  transition: transform 0.3s;
  &.open {
    transform: rotate(180deg);
  }
}

.datalistTop {
  bottom: 100%;
  transition:
    transform 0.3s,
    opacity 0.3s;
  opacity: 1;
  transform: translateY(0);
  @starting-style {
    max-height: 21em;
    opacity: 0;
    transform: translateY(1em);
  }
}
