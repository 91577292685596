.__VerticalSeparator {
  border-left: 1px solid var(--separator-color, #e4e7ed);
  margin: 0 27px;
  height: 100%;

  @media (max-width: 1366px) {
    margin: 0 5px;
  }

  &:first-child {
    margin: 0;
  }

  &:before {
    content: "";
  }
}
