.horizontal-windows-layout {
  min-height: inherit;
  display: grid;
  height: 100%;
  overflow: hidden;

  .horizontal-windows-column {
    min-height: inherit;
    height: 100%;
    overflow: hidden;

    .horizontal-windows-item {
      height: inherit;
      min-height: inherit;
      overflow: hidden;
    }
  }
}



