.nav {
  overflow-y: auto;
  
  .linkList {
    .link {
      position: relative;
      display: grid;
      grid-template-areas:
        "badge"
        "icon"
        "text";
      --badge-area: badge;
      color: var(--gl_text_constant_primary2);
      text-align: center;
      transition: background-color 0.3s;
      height: 58px;
      justify-items: center;
      font: var(--font-10-r);

      .icon {
        grid-row: badge / icon;
        grid-column: 1;
        align-self: center;
        color: var(--gl_icon_constant_secondary);
        transition: color 0.3s;
      }

      &:hover {
        background-color: var(--gl_dividerBorder_hover);
        background-color: rgba(from var(--gl_dividerBorder_hover) r g b / 0.75);
        .icon {
          color: var(--gl_icon_constant_primary2);
        }
      }

      &.pending {
        background-color: rgba(
          from var(--sidebar_active_background) r g b / 0.75
        );
        .icon {
          color: var(--gl_icon_constant_primary2);
        }
      }

      &.active {
        background-color: var(--sidebar_active_background);
        .icon {
          color: var(--gl_icon_constant_primary2);
        }
      }
    }
  }
}
