.container{
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  position: relative;
  background: var(--scheme-bg);
  height: 100%;
}

.scheme{
  display: flex;
  min-height: 0;
  flex: 1;

  > svg {
    min-height: 0! important;
    margin-right: 65px;
  }
}


.bookingListButton{
  position: absolute;
  left: 12px;
  top: 12px;
  width: 41px;
  height: 41px;
  background: var(--legendButton_default_background);
  border: 1px solid var(--gl_dividerBorder_primary);
  color: var(--gl_icon_constant_secondary);
}

.legend{
  position: absolute;
  right: 12px;
  top: 12px;
}
