.widget{
  margin: 16px 16px 0 16px;
  font: var(--font-12-b);
}

.title {
  font: var(--font-16-b);
  color: var(--gl_text_secondary_2);
}

.container:last-child{
  margin-top: 10px;
}

.subtitle{
  font: var(--font-14-r);
  color: var(--gl_text_inverse);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  svg{
    margin-right: 4px;
    color: var(--gl_text_secondary_2);
  }

  button{
    display: flex;
    align-items: center;
    color: var(--gl_text_secondary_2);
    font: var(--font-14-r);
  }
}

.info{
  padding: 8px;
  border: 1px solid var(--gl_dividerBorder_primary);
  border-radius: 3px;
  background: var(--select_disabled_background);
  color: var(--gl_text_secondary_disabled);
  font: var(--font-14-r);
}