.container {
  width: 239px;
  padding: 4px 0;
  gap: 8px;
}

.header {
  color: var(--gray);
  padding: 0px 8px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 12px;

  font: var(--font-14-r);
}

.trigger {
  display: inline-flex;
  .isOpen {
    opacity: 0.5;
  }
  .arrow {
    transition: transform 0.2s ease;
    color: var(--gl_icon_const_inverse);
    &.isOpen {
      transform: rotate(180deg);
    }
  }
}

.popup {
  max-height: 308px;
  max-width: 239px;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &:-webkit-scrollbar {
    display: none;
  }

  .category {
    display: flex;
    margin: 0;
    width: 215px;
    height: 44px;
    padding: 8px 12px;
    align-items: center;
    color: var(--gl_text_secondary_disabled);
    font: var(--font-14-r);
  }
}

.item {
  border-bottom: 1px solid var(--gl_dividerBorder_primary);
  padding: 0px 8px;
  overflow: hidden;
  &::before {
    content: "";
    flex-shrink: 0;
    background-color: var(--status-color);
    width: 24px;
    aspect-ratio: 1;
    border-radius: 50%;
  }
  .statusName {
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.item:hover {
  background-color: var(--selectMenu_hover_background);
}
.item.active {
  background-color: var(--selectMenu_focus_background);
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.bookingStatus {
  background: var(--status-color, var(--gray));
}
