.clientForm {
  display: grid;
  column-gap: 12px;
  row-gap: 16px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(100%, max(172px, 30%)), 1fr)
  );

  .phone,
  .formError {
    grid-column: 1/-1;
  }

  .placeholderMatch:not(:focus)::placeholder {
    color: var(--gl_text_inverse);
    font: var(--font-12-r);
  }
}
