.Toastify__toast-container {
  display: grid;
  grid-template-columns: var(--toastify-toast-width);
  gap: 16px;
  width: 360px;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  border: 1px solid currentColor;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(55, 40, 0, 0.1);
  border-radius: 3px;
  gap: 15px;
  align-items: flex-start;
  display: flex;
  padding: 17px 20px;
  margin: 0;
  width: 360px;
}

.Toastify__toast-body {
  gap: 15px;
  align-items: flex-start;
}

@mixin toastColors($color) {
  $border: lighten(adjust-hue($color, 4), 15.88);
  background-color: lighten(adjust-hue($color, 9), 31.96);
  color: $color;
  border-color: $border;
  .Toastify__progress-bar {
    background: $border;
  }
}

.Toastify__toast--info {
  $color: #177ff4;
  @include toastColors($color);
}
.Toastify__toast--success {
  $color: #2f754d;
  @include toastColors($color);
}
.Toastify__toast--warning {
  $color: #ff7a45;
  @include toastColors($color);
}
.Toastify__toast--error {
  $color: #cc5962;
  @include toastColors($color);
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close {
  flex: none;
  width: 18px;
  path {
    fill: currentColor;
  }
}

.Toastify__message {
  color: var(--mine-shaft);
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
}

.Toastify__notificationContent-header {
  display: flex;
  align-items: center;
  gap: 20px;
  --icon-color: currentColor;
}
