.wrapper {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  border: 1px solid var(--gl_dividerBorder_primary);
}

.wrapperError {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  border: 1px solid var(--gl_icon_constant_danger);
}

.item {
  cursor: pointer;
  background-color: var(--radioButton_default_background_2);
  width: 100%;
  height: 31px;
  border-right: 1px solid var(--gl_dividerBorder_primary_2);
  display: grid;
  align-items: center;
  justify-content: center;
  color: var(--gl_text_inverse);
  overflow: hidden;

  &:last-child {
    border: none;
  }

  &:disabled {
    opacity: 0.7;
  }
}

.active {
  background-color: var(--radioButton_active_background);
  color: var(--gl_text_constant_primary2);
}