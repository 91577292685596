#booking {
  --top-input-width: 270px;
  @media (max-width: 950px) {
    --normalized-block-content-height: calc(
      var(--normalized-block-height) - 120px
    );
  }
  @media (max-width: 1366px) {
    --top-input-width: 150px;
  }
  grid-template-rows: min-content auto;

  .right-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    .input-search {
      flex: 1;
    }
  }


  .cards {
    margin: var(--cards-block-margin);
    padding: 0 var(--header-spacing) 0 var(--header-spacing);
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 15px;
    height: 100%;
    transition: height var(--animation-duration);
    box-sizing: border-box;
    overflow: var(--card-content-overflow);
    animation: var(--card-content-animation);

    & > :first-child {
      margin-top: var(--card-ends-marging);
    }
    & > :last-child {
      margin-bottom: var(--card-ends-marging);
    }
  }
}
