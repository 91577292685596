
.table{
  border-spacing: 0 4px;
  border-collapse: separate;
  width: 100%;

  tbody td{
    vertical-align: middle;
  }
}

.header{
  position: sticky;
  top: 0;
  background-color: var(--gl_background_1);

  th {
    font: var(--font-14-b);
    padding: 15px 12px;
    text-align: center;
    color: var(--gl_text_constant_secondary_disabled2);
    text-transform: uppercase;
    padding-top: 0;
    &:first-child{
      width: 200px;
    }
    &:last-child{
      width: 62px;
    }
  }
}


.row{
  box-shadow: 0px 2px 3px rgba(81, 86, 95, 0.15);
  border-radius: 3px;

  td {
    text-align: center;
    font: var(--font-14-r);
    color: var(--gl_text_inverse);
    border-top: 1px solid var(--gl_dividerBorder_primary_2);
    border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
    background-color: var(--gl_background_2);

    &:first-child{
      border-left:  1px solid var(--gl_dividerBorder_primary_2);

    }
    &:last-child{
      background: var(--table_amaunt_background);
      border: 1px solid var(--gl_dividerBorder_primary_2);
    }
    &:first-child, &:last-child{
      padding: 15px 12px;
    }
  }
}

.cell{
  color: var(--gl_text_secondary_disabled);
}
