.datalist {
  padding-block: 8px;
  position: absolute;
  width: 100%;
  background-color: var(--selectMenu_default_background_1);
  border-radius: 3px;
  display: block;
  max-height: 21em;
  transition: max-height 0.3s;
  border: 0 none;
  overflow-x: hidden;
  margin-top: 8px;
  overflow-y: auto;
  box-shadow: var(--Border_Lightest);
  @starting-style {
    max-height: 0;
  }

  .group {
  }

  .item {
    padding: 8px;
    color: var(--gl_text_inverse);
    font: var(--font-12-r);
    transition:
      color 0.3s,
      background-color 0.3s;

    cursor: pointer;
    &:hover {
      background-color: var(--selectMenu_hover_background);
    }
    &:focus {
      background-color: var(--selectMenu_hover_background);
    }

    &:checked {
      background-color: var(--selectMenu_focus_background);
    }
  }
}
