@import 'styles/mixins';

#root main#settings {
  --z-index-multi: 0;
  position: relative;

  .block-content {
    &.restManagement {
      overflow-y: scroll;
    }
    &.appointRoles {
      @media (max-width: 1366px) {
        .search-block {
          justify-content: space-between;
        }
      }
    }

    .cards,
    .sections {
      height: auto;
      overflow: auto;

      .booking-split-section {
        img {
          width: 24px;
          height: 24px;
        }

        display: flex;
        padding: 0px 10px;
        align-items: center;
        height: 65px;
        min-height: 65px;
        font: var(--font-20-b);
        color: #484f54;

        span {
          margin-left: 15px;
        }

        background-color: white;
        position: sticky;
        top: 0;
        z-index: 1;

        @media (max-width: 1366px) {
          font: var(--font-14-b);
          height: 50px;
          min-height: 50px;
        }
      }
    }

    .appointHostess,
    .appointRoles {
      &.restaurantManagement > div.active {
        --detail-height: max-content;
      }

      > div.active {
        --detail-height: auto;
      }

      &.one-tab div.active {
        --detail-height: auto;
      }

      .rest-info {
        position: sticky;
        top: 0px;
        cursor: pointer;
        z-index: 1;
        align-items: center;
        height: 64px;
        padding: 0px 24px 0px 20px;
        background-color: var(--collapse_administrations_default_background);
        border: 1px solid var(--gl_dividerBorder_primary);
        box-shadow: var(--shadow-thick);

        > span {
          font: var(--font-20-b);
          color: var(--gl_text_inverse);
        }

        > div {
          display: flex;
          align-items: center;

          .clickable {
            font: var(--font-16-b);
          }

          figure {
            cursor: pointer;
            margin: 0 0 0 15px;
          }
        }
      }

      @media (max-width: 1366px) {
        .rest-info > span {
          font: var(--font-16-b);
        }

        .clickable {
          font: var(--font-14-b);
        }
      }

      .table.rows {
        max-height: var(--detail-height);
        width: calc(100% - 6px);

        > :first-child:not(.message) {
          margin-top: 8px;
        }

        .table-row {
          > div {
            display: flex;
          }

          .edit {
            @include icon-svg-color-size('edit', var(--dodger-blue), 24px);
          }

          img {
            margin-left: 10px;
          }

          .edit,
          img {
            cursor: pointer;
          }

          .details {
            display: flex;
            align-items: center;
            width: 80%;
            color: var(--gl_text_inverse);

            .name {
              width: 35%;
            }

            .phone,
            .rest {
              width: 20%;
              display: flex;
              align-items: center;
              margin-left: 20px;

              svg {
                color: var(--gl_icon_constant_primary);

                path {
                  fill: var(--gl_icon_constant_primary);
                }
              }

              span {
                margin-left: 10px;
              }
            }

            > :first-child.rest {
              margin-left: 0px;

              img {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }

    .statuses {
      > div.active {
        align-self: stretch;
        --detail-height: max-content;
      }

      .rest-info {
        position: sticky;
        top: 0px;
        cursor: pointer;
        z-index: 1;
        align-items: center;
        height: 64px;
        padding: 0px 24px 0px 20px;
        background-color: var(--collapse_administrations_default_background);
        border: 1px solid var(--gl_dividerBorder_primary);
        box-shadow: var(--shadow-thick);

        > span {
          font: var(--font-20-b);
          color: var(--gl_text_inverse);
        }

        > div {
          display: flex;
          align-items: center;

          .clickable {
            font: var(--font-16-b);
          }

          figure {
            cursor: pointer;
            margin: 0 0 0 15px;
          }
        }
      }

      @media (max-width: 1366px) {
        .rest-info > span {
          font: var(--font-16-b);
        }

        .clickable {
          font: var(--font-14-b);
        }
      }

      .table.rows {
        height: var(--detail-height);
        width: calc(100% - 6px);
        transition: all 0.9s ease;

        > :first-child:not(.message) {
          margin-top: 16px;
        }

        .table-row {
          > div {
            display: flex;
          }

          .edit {
            @include icon-svg-color-size('edit', var(--dodger-blue), 24px);
          }

          img {
            margin-left: 10px;
          }
          .controls {
            display: flex;
            align-items: center;
            gap: 16px;
          }

          .edit,
          img {
            cursor: pointer;
          }

          .details {
            display: flex;
            align-items: center;
            width: 80%;
            color: var(--gl_text_inverse);

            .status-name {
              display: inline-flex;
              color: var(--gl_text_content_primary);
              height: 20px;
              padding: 0px 10px;
              justify-content: center;
              align-items: center;
              width: max-content;
              border-radius: 20px;
              font: var(--font-12-b);
            }

            .phone,
            .rest {
              width: 20%;
              display: flex;
              align-items: center;
              margin-left: 20px;

              svg {
                color: var(--gl_icon_constant_primary);

                path {
                  fill: var(--gl_icon_constant_primary);
                }
              }

              span {
                margin-left: 10px;
              }
            }

            > :first-child.rest {
              margin-left: 0px;

              img {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }

    .appointRoles > div.active {
      --detail-height: auto;

      &.count-5 {
        --detail-height: 326x;
      }
    }
    .collapses {
      overflow: auto;
    }
  }

  .select-mode {
    width: 200px;
  }

  .search-block {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    gap: 5px;
    min-height: 50px;
    height: 50px;

    .hostess {
      min-width: 200px;
    }

    .reset-datepicker {
      right: 5px;
    }

    @include tablet {
      margin-left: 0;
      margin-top: 15px;
    }

    &.appointHostess > label {
      flex-grow: 1;
    }

    &.appointHostess .input-search {
      min-width: 230px;
      max-width: 260px;
    }

    &:not(.changeBook) > :not(:first-child) {
      margin-left: 20px;
    }

    button.primary {
      height: var(--height-a);
    }

    .separ {
      margin: 0px 15px;
    }

    .datepicker.from {
      margin-left: 20px;
    }

    .from,
    .to {
      min-width: 120px;
    }

    .datepicker.to {
      margin-right: 20px;
    }
  }

  .card-booking {
    .table {
      .table-cell- {
        width: 39%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &hostess {
          width: 30%;
        }

        &dateTime {
          width: 16%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &action {
          width: 20%;
        }

        &param {
          width: 34%;
        }
      }
    }
  }

  .empty-list {
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font: var(--font-24-b);
    color: var(--cadet-blue);
  }
}

.access_rules,
.new-tags {
  .create_rule_button {
    width: min-content;
  }

  .access_rules_box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    > div {
      margin: 0;
      width: auto;
      border: none;
      box-shadow: none;
      background: transparent !important;
      border: none !important;
      box-sizing: border-box;
      box-shadow: 0px 2px 3px rgba(81, 86, 95, 0.15);
      border-radius: 3px;

      .Collapse_header__4TEoy {
        .collapseHeader {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font: var(--font-14-b);

          p {
            margin-bottom: 0;
            align-content: center;
            :last-child {
              color: var(--gl_text_secondary_2);
            }
          }

          > :last-child {
            color: var(--gl_text_accent);
            padding-bottom: 4px;
            border-bottom: 2px dashed var(--gl_text_accent);
          }

          margin-right: 24px;
        }
      }
    }
  }

  .tags_button {
    display: flex;
    width: 100%;
    justify-content: space-between;

    > :first-child {
      border: none !important;
      background: none !important;
    }
  }

  .groupsValue {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 12px;
    gap: 8px;

    button {
      font: var(--font-12-b);
    }
  }
}

.arrow {
  color: var(--gl_icon_inverse);
  display: flex;
  width: 28px;
  height: 28px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  transition: all 0.3s ease;
  margin: 0;
}
.arrow.active {
  background-color: var(--collapseButton_active_background);
  color: var(--gl_icon_accent);
  transform: rotate(180deg);
}
