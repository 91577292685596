.switchToggleContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.switchToggleInput {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.switchToggle {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  background-color: var(--gl_background_gray_3);
  border-radius: 50px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.switchToggle::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 24px;
  height: 24px;
  background-color: var(--gl_icon_constant_primary2);
  border-radius: 50px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.15);
  transition:
    left 0.2s ease-in-out,
    width 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}

.switchToggleInput:checked + .switchToggle {
  background-color: var(--gl_background_accent);
}

.switchToggleInput:checked + .switchToggle::after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchToggleInput:disabled {
  pointer-events: none;
}

.switchToggleInput:disabled + .switchToggle {
  cursor: not-allowed;
  background: #eaeaea;
}

.switchToggleInput:disabled + .switchToggle::after {
  background: #f8f8f8;
}

.switchToggleContainer:active
  .switchToggleInput:not([disabled])
  + .switchToggle::after {
  width: 34px;
}
