@mixin tablet {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin icon-svg-color-size($name, $color, $size) {
  @include icon-svg($name);
  background-color: $color;
  width: $size;
  height: $size;
  -webkit-mask-size: $size $size;
  mask-size: $size $size;
}

@mixin icon-svg($name) {
  -webkit-mask-image: url("/assets/img/" + $name + ".svg");
  mask-image: url("/assets/img/" + $name + ".svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@mixin icon-svg-size($name, $size) {
  @include icon-svg($name);
  width: $size;

  height: $size;
  -webkit-mask-size: $size $size;
  mask-size: $size $size;
}

@mixin icon-svg-color($name, $color) {
  @include icon-svg($name);
  background-color: $color;
}


@mixin forDarkTheme(){
  html {
    @media (prefers-color-scheme: dark) {
      &:not([data-mode]) {
        @content;
      }
    }
    &[data-mode="dark"]{
      @content;
    }
  }
}
