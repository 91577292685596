.notifications{
  grid-area: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  :not(:first-child){
    margin-left: -5px;
  }
}
.iconWrapper{
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  box-shadow: var(--shadow-strong);
}

.overbooking{
  background-color: var(--floorplanLegend_overbooking_background);
  color: var(--floorplanLegend_overbooking_content);
  font-size: 17px;
}

.deposit{
  background-color: var(--floorplanLegend_deposite_background);
  color: var(--floorplanLegend_deposite_content);
  font-size: 17px;
  font-weight: 700;
}

.lock{
  color: var(--floorplanLegend_lock_content);
  background-color: var(--floorplanLegend_lock_background);
}
