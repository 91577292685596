#root {
  --aside-name-font: var(--font-14-r);
  --aside-time-font: var(--font-24-b);
  --aside-date-font: var(--font-14-r);
  --aside-width: 94px;

  --calendar-margin: 175px;
  --calendar-control-width: 100px;

  --header-book-button-plus: none;
  --header-book-button-text: block;
  --header-book-button-width: 160px;

  --header-font-a: var(--font-14-r);
  --header-height: 60px;
  --header-spacing: 20px;

  --main-block-top-section-height: 72px;
  --main-block-top-section-font: var(--font-22-b);

  --normalized-block-height: calc(var(--win-height) - var(--header-height) - var(--pad) - var(--pad));
  --normalized-block-content-height: calc(var(--normalized-block-height) - var(--main-block-top-section-height));

  --nav-cell-height: 90px;
  --restaurant-gtc: 190px 160px;
  --user-pic-side: 52px;

  @media (max-width: 1530px) {
    --calendar-margin: 0;
  }

  @media (max-width: 1800px) {
    --header-book-button-plus: flex;
    --header-book-button-text: none;
    --header-book-button-width: 40px;
  }

  @media (max-width: 1366px) {
    --aside-name-font: var(--font-10-r);
    --aside-time-font: var(--font-18-b);
    --aside-date-font: var(--font-12-r);


    --calendar-margin: 120px;
    --calendar-control-width: 82px;

    --header-font-a: var(--font-12-r);
    --header-height: 60px;
    --header-spacing: 10px;

    --main-block-top-section-height: 46px;
    --main-block-top-section-font: var(--font-16-b);

    --header-book-button-width: 34px;

    --nav-cell-height: 70px;
    --restaurant-gtc: 180px auto;
    --user-pic-side: 40px;
  }

  @media (max-width: 950px) {
    --header-spacing: 4px;
  }

  @media (max-width: 970px) {
    --calendar-margin: 0;
  }
}
