.no-limit{
  max-height: none;
}
.report {
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .titled-block {
    --spacing: 20px;
    padding: 0px var(--spacing);
    &:not(:first-child) {
      margin-top: var(--spacing);
    }

    &.time {
      margin-bottom: var(--spacing);
    }

    &.date-time > div {
      :global {
        .datepicker {
          &.no-icon::after {
            display: none;
          }
          z-index: 8;
          width: 50%;
          .react-datepicker-wrapper {
            width: 100%;
            input {
              border: var(--border-2-solid-botticelli);
            }
          }
          height: var(--height-a);
        }
      }
    }

    &.status {
      z-index: 7;
    }

    > div {
      display: flex;
    }

    h3.required:after {
      content: "*";
      font: var(--font-14-b);
      @media (max-width: 1366px) {
        font: var(--font-12-b);
      }
    }

    .title {
      color: var(--gl_text_inverse);
      font: var(--titled-font);
      margin-left: 1px;
      font-weight: 550;
      margin-bottom: 8px;
    }

    @media (max-width: 1366px) {
      --spacing: 10px;
    }
  }

  .separ {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #525252;
    margin: 0 15px;
    font-weight: 500;
  }
}

.status{
  display: block !important;
}
