.label {
  display: block;
  font: var(--font-13-r);
  margin-bottom: 5px;
  color: var(--gl_text_inverse);
  position: relative;
}

.required {
  &::after{
    content: "*";
    color: rgb(190	96	100);
    position: absolute;
    margin-left: 3px;
  }
}
