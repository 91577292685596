.tag {
    display: inline-flex;
    height: 36px;
    padding-inline: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    font: var(--font-12-b);
    border: 2px solid transparent;
    cursor: pointer;

    max-width: 300px;

    user-select: none;
    
    .label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .radio {
        flex-shrink: 0;

        .circle, .check {
            display: flex;
            width: 16px;
            aspect-ratio: 1;
            border-radius: 100%;
        }

        .circle {
            border: 1px solid var(--gl_icon_const_inverse);
            opacity: 0.5;
        }

        .check {
            background-color: var(--gl_icon_const_inverse);
            justify-content: center;
            align-items: center;
            color: var(--gl_icon_constant_primary2);
        }
    }

}

.checked {
    border: 2px solid var(--gl_dividerBorder_secondary);
}

.disabled {
    opacity: 0.45;
    pointer-events: none;
}
