.rating {
  margin: 0;
  meter {
    display: none;
  }
  svg {
    width: 12px;
    height: 12px;
    color: var(--gl_icon_primary_1);
  }
  @for $i from 1 through 5 {
    meter[value="#{$i}"] ~ svg:nth-of-type(-n + #{$i}) {
      color: var(--gl_text_inverse);
    }
  }
}
