.modalContentAccess {
    flex-grow: 1;
    overflow-y: hidden;
    padding: 0px;
    margin: 0px;
}

.form {
    display: grid;
    gap: 12px;
    box-sizing: border-box;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: 100%;
    padding-bottom: 90px;

    .content {
        align-content: start;
        padding: 16px 16px 0px 16px;
        position: relative;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: auto;
        gap: 24px;
        overflow: auto;
        height: -webkit-fill-available;


        &> :nth-child(2) {
            display: grid;
            grid-template-columns: repeat(2, auto);
            gap: 8px;
        }

        &> :nth-child(3) {
            display: grid;
            grid-template-columns: auto auto;


            :first-child {
                grid-column: span 1;
            }

            :nth-child(2) {
                grid-column: span 1;
                justify-self: end;
                color: var(--textButton_default_text);
                font: var(--font-12-b);
                cursor: pointer;
            }


            :last-child {
                grid-column: span 2;
            }
        }

        .formTitle {
            color: var(--gl_text_secondary_2);
            font: var(--font-16-r);
        }

        label>span {
            font: var(--font-12-r);
        }

        .tagsContainer {
            display: flex;
            gap: 4px;
            flex-wrap: wrap;

            button {
                font: var(--font-12-b);
            }
        }

        label>.addNewTag {
            display: flex;
            gap: 8px;
            width: -webkit-fill-available;

            label {
                width: 100%;
            }
        }
    }
}




.colors {
    padding: 4px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    padding-top: 4px;

    .eachColor {
        width: 44px;
        height: 31px;
        border-radius: 3px;
        cursor: pointer;
        box-sizing: border-box;

        button {
            width: 100%;
            height: 100%;
            border-radius: 3px;
        }
    }

    .eachColor.currentColor {
        position: relative;

        ::after {
            content: "";
            position: absolute;
            width: 109%;
            height: 109%;
            border: 2px solid var(--gl_text_accent);
            border-radius: 5px;
        }
    }
}

.controls {
    position: absolute;
    bottom: 0px;
    width: -webkit-fill-available;
}