.guestAndTimeForm {
  display: grid;
  row-gap: 16px;
  align-content: start;
  overflow: hidden;
}

.guestCountInput {
  display: block;
  margin-top: 4px;
  & > div {
    display: inline-flex;
    width: 100%;
    input {
      max-width: 20%;
    }
    span {
      flex: 1;
    }
  }
}

.timeSlot {
  display: grid;
  row-gap: 2px;
  justify-items: center;
  align-content: center;
  font: var(--font-14-r);

  .coveredIn {
    transition:
      background-color 0.3s ease-in-out,
      color 0.3s ease-in-out;
    font: var(--font-12-r);
    column-gap: 3px;
    color: var(--gl_text_secondary_disabled);
    --icon-color: var(--gl_icon_primary_1);
  }
}

input:checked + .timeSlot .coveredIn {
  color: var(--gl_icon_constant_primary2);
  --icon-color: var(--gl_icon_constant_primary2);
}
