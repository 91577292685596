.expandableContent {
  flex: 1;
  margin: 0;
  font: var(--font-14-r);
  color: var(--gl_text_secondary_disabled);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.showMoreButton {
  label {
    display: flex;
    padding: 4px 0;
    width: max-content;
    color: var(--gl_text_secondary_disabled);
    align-items: center;
    font: var(--font-14-b);
    cursor: pointer;
    h2 {
      color: var(--gl_text_inverse);
    }
    svg {
      transition: all 0.3s;
    }
  }

  label::before {
    content: attr(data-showmore);
  }
}

.hiddenCheckbox:checked + .expandableContent {
  -webkit-line-clamp: initial;
}

.hiddenCheckbox:checked ~ .showMoreButton > label > svg {
  transform: rotate(180deg);
}

.hiddenCheckbox:checked ~ .showMoreButton > label::before {
  content: attr(data-showless);
}
