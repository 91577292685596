.container{
  display: flex;
  align-items: center;
  gap: 32px;
  grid-column: 1 / span 3;
}

.title {
  color: #fff;
  margin-bottom: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  @media(max-width: 1060px){
    display: none;
  }
}

.search{
  display: flex;
  align-items: center;
  gap: 15px;
  flex:  1;
  --select_default_background_1: var(--select_default_background_2);
}

.rest{
  flex: 0 0 140px;
}

.searchType{
  flex: 0 0 190px;
}

.searchValue{
  flex: 1 0 ;
}
