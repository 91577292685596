.filters {
  color: var(--gl_text_secondary_disabled);
  --button-height: 32px;

  display: flex;
  align-items: center;

  margin: var(--header-spacing);
  margin-bottom: 0px;
}
.selectFilter {
  display: flex;
  gap: 8px;
  width: 290px;
}

.search {
  display: flex;
  flex: 1 0;
  margin-right: 24px;
}

.searchInput {
  flex: 1 0;
  input,
  input[type='text'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.searchButton {
  color: var(--gl_text_secondary_disabled);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  height: 31px;

  font: var(--font-12-r);
}

.checkboxContainer {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  label {
    margin-right: 8px;
  }
}
