[id="root"] {
  background-color: var(--gl_background_floorplan);
}

.auth {
  width: min(904px, 98vw);
  margin: auto;
  max-height: 100%;
  border: 1px solid var(--gl_dividerBorder_primary);
  border-radius: 3px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 350px), 1fr));
  align-items: center;
  justify-content: center;

  .header {
    display: grid;
    min-height: min(524px, 30vh);
    row-gap: 24px;
    background-image: linear-gradient(
        hsla(0, 0%, 0%, 0.55),
        hsla(0, 0%, 0%, 0.55)
      ),
      var(--brand-bg);

    background-size: cover;
    background-repeat: no-repeat;
    place-content: center;
    text-align: center;
    height: 100%;
    font: var(--font-18-b);
    color: var(--gl_text_constant_primary2);
    img {
      font: var(--font-60-b);
      color: var(--gl_text_constant_primary2);
    }
  }
  .authForm {
    padding: 44px;
    display: grid;
    overflow-y: auto;
    row-gap: 24px;
    height: 100%;
    align-content: space-between;
    .labeled {
      display: grid;
      row-gap: 12px;
      background-color: transparent;
      &.hide {
        display: none;
      }
      & > span {
        font: var(--font-14-b);
        margin: 0;
      }
    }

    .input {
      transition: box-shadow 0.5s ease-in-out 0s;
      font: var(--font-16-b);
      height: 58px;
      background-color: transparent;
    }

    & > button {
      height: 67px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--gl_text_inverse);
  box-shadow: inset 0 0 58px 58px
    rgba(from var(--gl_background_accent) r g b / 0.2);
  border: 1px solid var(--gl_dividerBorder_accent);
}
