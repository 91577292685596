.radioGroupContainer {
  overflow: hidden;
  display: flex;

  .arrow {
    aspect-ratio: 1;
    width: 31px;
    min-width: 31px;
    border: 1px solid var(--gl_dividerBorder_primary);
    align-content: center;
    text-align: center;
    color: var(--gl_icon_constant_secondary);
    transition:
      background-color 0.3s ease-in-out,
      color 0.3s ease-in-out;

    &:first-of-type {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-of-type {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:hover {
      background-color: var(--selectMenu_hover_background);
    }

    & + .multiShiftButtons {
      padding-inline: 0;
    }
  }

  .multiShiftButtons {
    flex-wrap: nowrap;
    text-align: center;
    overflow-x: auto;
    //scroll-snap-type: x mandatory;
    //scroll-padding: 2rem;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
    scroll-behavior: smooth;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border: none;
    }

    --radio-button-width: 94px;
    --radio-button-column-gap: 4px;
    --radio-button-row-gap: var(--radio-button-column-gap);
    --radio-button-border-color: var(--gl_dividerBorder_primary);
    --radio-button-border-width: 1px;
    --radio-button-border-radius: 3px;

    label {
      scroll-snap-align: start;
      align-content: center;
      padding-inline: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-wrap: balance;
    }
  }
}
