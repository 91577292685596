.errorList {
  display: grid;
  row-gap: 2px;
  margin: 0;
}

.error {
  font: var(--font-12-r);
  color: var(--gl_text_constant_danger);
  margin: 0;
}
