#modal {
  .change-hostess {
    --pad-left: 20px;
    color: #525252;
    background: var(--gl_background_3);
    box-shadow: var(--shadow-lightest);
    border-radius: 3px;
    width: 700px;
    min-height: 250px;
    max-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-block-A {
      height: 72px;

      h2 {
        color: #000;
        font: var(--font-22-b);
      }

      img {
        cursor: pointer;
      }
    }

    .content {
      overflow: auto;
      padding: 15px var(--pad-left) 25px var(--pad-left);
      max-height: calc(650px - 72px - 76px);

      .titled-block {
        > div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          > :first-child { width: 55%; }
          > :nth-child(2) { width: 40%; margin-left: 15px; }
        }

        &.add > div > :nth-child(2):not(h3) {
          width: 40%; margin-left: 15px;
        }

        h3 {
          font: var(--font-14-b);
          margin-bottom: 10px;
          color: #525252;
        }

        .hostess {
          display: flex;
          margin-top: 15px;
          img { margin-left: 10px; cursor: pointer; }
        }

        &.add {
          > :nth-child(2) {
            margin-top: 0px;
          }
          .mock-col{
            width: 34px;
          }
        }
      }
    }

    .form-controls {
      box-sizing: border-box;
      height: 76px;
      padding: 18px var(--pad-left);
      background-color: var(--bottomBar_background_2);
      display: flex;
      align-items: center;
      button { height: var(--height-a); }
    }

    @keyframes anim-error {
      from {
        transform: translate(0px, -15px);
        opacity: 0;
        margin-top: 0px;
        height: 0px;
      }
      to {
        transform: translate(0px, 0px);
        opacity: 1;
        margin-top: 8px;
        height: 16px;
        @media(max-width: 1366px) {
          height: 14px;
        }
      }
    }

    .error-block {
      font: var(--font-14-b);
      animation-duration: 0.2s;
      color: #E08D8D;
    }
    .error-block.active {
      margin-top: 8px;
      animation-name: anim-error;
    }

    @media(max-width: 1366px) {
      --pad-left: 10px;
      min-height: 200px;
      max-height: 550px;
      width: 440px;
      .error-block {
        font: var(--font-12-b);
      }
      .top-block-A {
        height: 50px;
        h2 { font: var(--font-16-b); }
      }
      .content {
        max-height: calc(550px - 72px - 76px);
        .titled-block h3 { font: var(--font-12-b); }
      }
      .form-controls {
        box-sizing: border-box;
        height: 64px;
        button {
          position: relative;
          &:disabled {
            opacity: 1;
            background-color: #b3c7fc;
            color: #f5f7ff;
          }

          &:hover {
            .tooltip {
              visibility: visible;
              opacity: 1;
            }
          }

          .tooltip {
            top: -31px;
            padding-bottom: 6px;

            &::after {
              right: 112px;
              bottom: -4px;
            }
          }
        }
      }
    }
  }
}
