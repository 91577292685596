.root {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  > *:first-child {
    height: 100%;
  }

  div {
    font-family: Roboto, sans-serif;
  }
}

.theme {
  // --- Общее

  // Цвет акцента
  --brand: var(--columnButton_active_background);
  --ant-primary-color: var(--brand);
  --ant-primary-color-hover: var(--brand);

  // --- Список

  // Фон подложки списка сообщений
  // По умолчанию: #fafafa
  --chat-list-bg: var(--gl_background_gray_2);

  // --- Сообщения

  // Оператор: Фон
  // По умолчанию: #ffffff
  --chat-msg-user-bg: var(--gl_background_2);

  // Оператор: Текст
  // По умолчанию: #595959
  --chat-msg-user-color: var(--gl_text_inverse);

  // Оператор: Цвет рамки
  // По умолчанию: #d9d9d9
  --chat-msg-user-border-color: var(--gl_dividerBorder_primary);

  // Оператор: Имя
  // По умолчанию: #8c8c8c
  --chat-msg-user-sender-color: var(--gl_text_secondary_disabled);

  // Оператор: Время
  // По умолчанию: #8c8c8c
  --chat-msg-user-time-color: var(--gl_text_secondary_disabled);

  // Оператор: Цвет разделитель темы и тела письма
  // По умолчанию: #f5f5f5
  --chat-msg-user-subj-divider-color: var(--gl_dividerBorder_primary_2);

  //-

  // Клиент: Фон
  // По умолчанию: --brand
  // --chat-msg-customer-bg: ;

  // Клиент: Текст
  // По умолчанию: #ffffff
  --chat-msg-customer-color: var(--gl_text_constant_primary2);

  // Клиент: Цвет рамки
  // По умолчанию: --brand
  // --chat-msg-customer-border-color: ;

  // Клиент: Имя
  // По умолчанию: #ffffff
  --chat-msg-customer-sender-color: var(--gl_text_constant_primary2);

  // Клиент: Время
  // По умолчанию: #f5f5f5
  --chat-msg-customer-time-color: var(--gl_text_constant_primary2);

  // Клиент: Цвет разделитель темы и тела письма
  // По умолчанию: #f5f5f5
  --chat-msg-customer-subj-divider-color: var(--gl_text_constant_primary2);

  //-

  // Системные сообщения: Фон
  // По умолчанию: #ffffff
  --chat-msg-system-bg: #fefeea;

  // Системные сообщения: Текст
  // По умолчанию: #595959
  --chat-msg-system-color: var(--gl_text_content_primary);

  // Системные сообщения: Цвет рамки
  // По умолчанию: #d9d9d9
  --chat-msg-system-border-color: var(--gl_dividerBorder_primary);

  // Системные сообщения: Имя
  // По умолчанию: #8c8c8c
  --chat-msg-system-sender-color: var(--gl_text_secondary_disabled);

  // Системные сообщения: Время
  // По умолчанию: #8c8c8c
  --chat-msg-system-time-color: var(--gl_text_secondary_disabled);

  // Системные сообщения: Цвет разделитель темы и тела письма
  // По умолчанию: #f5f5f5
  --chat-msg-system-subj-divider-color: var(--gl_dividerBorder_primary_2);

  // Градиент под непрочитанными сообщениями
  // По умолчанию: linear-gradient(to right, var(--chat-msg-user-border-color), var(--chat-list-bg))
  // --chat-msg-unseen-container-bg: ;

  // --- Редактор

  // Цвет hover у табов выбора канала
  --ant-primary-5: var(--columnButton_active_background);

  // Фон выпадающих списков (например, выбор email). ВАЖНО: должен быть глобальным
  --ant-primary-1: var(--guestsItemlist_hoverActive_background);

  // Фон редактора
  // По умолчанию: #ffffff
  --chat-sender-bg: var(--gl_background_1);

  // Фон футера
  // По умолчанию: #ffffff
  --chat-sender-footer-bg: var(--bottomBar_background_1);

  //-

  // Фон полей ввода
  // По умолчанию: transparent
  --chat-sender-inputs-bg: ar(--select_default_background_1);
  // По умолчанию: var(--chat-sender-inputs-bg)
  // --chat-sender-inputs-hover-bg: ;

  // Цвет полей ввода
  // По умолчанию: не задан
  --chat-sender-inputs-color: var(--gl_text_inverse);

  // Цвет placeholder
  // По умолчанию: #bfbfbf
  --chat-sender-inputs-placeholder-color: var(--gl_text_secondary_disabled);

  // Шрифт полей ввода
  // По умолчанию: не задан
  --chat-sender-inputs-font: var(--input-font);

  // Цвет раздеоителя между полями ввода
  // По умолчанию: #f0f0f0
  --chat-sender-inputs-divider-color: var(--gl_dividerBorder_primary_2);

  // Цвет предупреждения templatesOnly
  // По умолчанию: #595959
  // --chat-sender-custom-text-color: ;

  //-

  // Радиус кнопок
  // По умолчанию: не задан
  --chat-sender-button-radius: 5px;

  // Шрифт кнопок
  // По умолчанию: не задан
  --chat-sender-buttons-font: var(--action-button-font);

  // Иконка загрузок
  // По умолчанию: #8c8c8c
  // --chat-sender-uploads-btn-color: ;

  //-

  // Цвет сообщения об ошибке
  // По умолчанию: #ff4d4f
  // --chat-sender-error-color: ;

  // --- Переопределения antd

  // Спрятать полоски в разделителе дат
  :global(.ant-divider) {
    &::before,
    &::after {
      visibility: hidden;
    }
  }

  // Закостылить цвет primary кнопки
  :global(.ant-btn-primary) {
    &,
    &[disabled],
    &[disabled]:hover {
      color: #fff;
      border-radius: 5px;
      font: var(--action-button-font);
      background: var(--brand);
      border-color: var(--brand);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  // Закостылить табы редактора
  :global(.ant-tabs-tab) {
    color: var(--gl_text_inverse);
  }
  :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn),
  :global(.ant-tabs-tab:hover) {
    color: var(--ant-primary-5);
  }
  :global(.ant-tabs-ink-bar) {
    background: var(--ant-primary-5);
  }
}

.error {
  height: 100%;
  display: flex;
  flex-direction: column;
  place-content: center;
  text-align: center;
  line-height: 1.5em;
  white-space: pre-line;
  color: var(--gl_text_constant_secondary_disabled2);
}
