@import "styles/mixins";

.isBookingListShown {
  grid-template-columns: 2fr 3fr;
  @include tablet {
    grid-template-columns: minmax(300px, 1fr) minmax(auto, 1fr);
  }
}

.hallScheme {
  position: relative;
  grid-column: span 2;
  min-height: 0;
  display: grid;
  overflow: hidden;
}

.content {
  @media (orientation: portrait) {
    display: grid;
    grid-template-columns: 400px auto;
    grid-gap: 5px;
    height: calc(100svh - var(--header-height));
  }
}

.editBooking {
  @include tablet {
    grid-column: 1 / span 2;
  }
}

.isEditOpen {
  grid-template-columns: minmax(570px, min-content) 1fr;
  @include tablet {
    grid-template-columns: 1fr;
    & > :last-child {
      display: none;
    }
    .bookingsList {
      display: none;
    }
  }
}

.fullHeight {
  height: 100%;
}

.hide {
  display: none;
}
