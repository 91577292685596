.main {
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  padding: 16px 0;
  margin: 0 -6px;
}

.item {
  display: grid;
  grid-row-gap: 4px;
  padding: 0 32px;

  &:not(:last-child) {
    border-right: 1px solid #D9D9D9;
  }

  .title {
    font-size: 12px;
    line-height: 14px;
    color: var(--gl_text_constant_secondary_disabled2);;
  }

  .description {
    color: var(--gl_text_inverse);
    font-size: 14px;
    line-height: 16px;
  }
}
