@import 'styles/mixins';

[data-theme='wrf'] {
  --dodger-blue: #4ba837;
  --selago: #e7f3e4;
  --alto: #313131;
  --header-bg: #313131;
  --header-color: #fbfbfb;
  --sidebar-color: var(--header-color);
  --boulder: var(--header-color);
  --separator-color: #414141;
  --botticelli: #bbd2bc;
  --catskill-white: #f6eee6;
  --scheme-bg: var(--catskill-white);
  --icon-color: #3c3b3b;

  //change colors cards
  --newCardBook_confirmed_border: var(--newCardBook_booked_border) !important;
  --status_constant_confirmed_background: var(
    --status_constant_notconfirmed_background
  ) !important;
  --newCardBook_confirmed_background: var(
    --newCardBook_booked_background
  ) !important;
  --newCardBook_confirmed_timer: var(--newCardBook_booked_timer) !important;

  //change colors table
  --floorplanTable_confirmed_background: var(
    --status_constant_notconfirmed_background
  ) !important;

  &[data-mode='dark'] {
    --floorplanTable_empty_background: #efefef;
    --floorplanNumber_empty_stroke: #efefef;
  }
}

[data-theme='lucky'] {
  --botticelli: #dcdde1;
  --sidebar-color: var(--gl_text_constant_primary2);
  --selago: #f2f4f8;
  --periwinkle-gray: #dbdfe7;
  --tropical-blue: #dcdde1;
  --cadet-blue: #b7bac1;
  --abbey: #4e5153;
  --dodger-blue: #2b2e31;
  --scheme-bg: var(--catskill-white);
  --font-roboto: Helvetica, sans-serif;
}

[data-theme='dubai'] {
  --dodger-blue: #4ba837;
  --selago: #e7f3e4;
  --alto: #313131;
  --header-bg: #313131;
  --header-color: #fbfbfb;
  --sidebar-color: var(--header-color);
  --boulder: var(--header-color);
  --separator-color: #414141;
  --botticelli: #bbd2bc;
  --catskill-white: #f6eee6;
  --scheme-bg: var(--catskill-white);
  --icon-color: #3c3b3b;
}
