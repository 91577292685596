@import "styles/mixins";

.menu {
  padding: 0;
  margin: 0;
  color: var(--tundora-dark);
}

.item {
  display: contents;
}

.button {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  text-align: left;
  width: 100%;
  white-space: normal;
  min-height: 44px;
  box-sizing: border-box;
  font: var(--font-14-r);
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--mercury, #e2e2e2);
  }

  .icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include forDarkTheme {
  .item {
    color: var(--wrf-white);
    &:not(:last-child) {
      border-bottom: 1px solid var(--08dp, #e2e2e2);
    }
  }
}
