.subHeader {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 12px;
  padding: 12px;
  font-family: Roboto, sans-serif;
}

.tags {
  grid-row: 3;
  grid-column: span 3;
  gap: 3px;
}

.sourceList {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &:-webkit-scrollbar {
    display: none;
  }

  .sourceCollapse {
    .organizationTitle {
      &::after {
        content: " " attr(data-count);
        color: var(--gl_text_secondary_disabled);
      }
    }
    // окрашиваем стрелку
    .arrow {
      color: var(--gl_icon_primary_1);
    }
  }

  .collapseContent {
    padding-inline: 0;
    .source {
      border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
      display: grid;
      align-items: center;
      grid-template-columns: auto min-content;
      padding: 16px 12px;
      transition: background-color 0.3s;
      &:hover,
      &:has(a.active) {
        background: var(--guestsItemlist_hoverActive_background);
      }

      &:has(a.pending) {
        opacity: 0.5;
      }

      .clientInfo {
        grid-area: 1 / 1 / auto / span 2;
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        & > * {
          display: inline-flex;
          align-items: center;
          color: var(--gl_text_inverse);
          font: var(--font-14-r);
        }

        .fullName {
          font: var(--font-14-b);
          column-gap: 8px;
          margin: 0;
        }

        .phone {
          column-gap: 4px;
          margin: 0;
        }
      }

      .clientDetail {
        grid-area: 1 / 2;
        path {
          fill: var(--gl_icon_constant_secondary);
        }
      }
    }
  }
}
