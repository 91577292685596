.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
