.noList {
  height: 100%;
  display: flex;
  flex-direction: column;
  place-content: center;
  text-align: center;
    font: var(--font-14-r);
  line-height: 1.5em;
  white-space: pre-line;
  color: var(--gl_text_constant_secondary_disabled2);
}

.list {
  padding: 0px 12px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 5px;
  &:-webkit-scrollbar {
    display: none;
  }
}

.divider {
  display: flex;
  color: var(--gl_text_secondary_2);
  padding: 24px 0px 12px 0px;
  justify-content: center;
  margin: 0;
  font: var(--font-12-r);

  &:first-of-type {
    padding: 16px;
  }
}
