.no-content {
  min-height: 340px;

  h3 {
    display: flex;
    font-size: 1.2rem;
    height: 340px;
    color: #888;
    align-items: center;
    justify-content: center;
  }
}

div.line-buttons {
  display: flex;
  padding: 12px 0;
  justify-content: center;
  align-items: center;

  > :nth-child(2) {
    margin-left: 10px;
  }

  button {
    background-color: var(--dodger-blue);
    border-radius: 5px;
    font: var(--font-13-b);
    color: white;
    height: var(--height-a);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;

    @media (max-width: 1366px) {
      padding: 7px 12px;
      font: var(--font-12-b);
    }
  }
}

.line {
  border-radius: 4px;
  border: #e2e2e2 1px solid;
  padding: 10px 5px;
  cursor: pointer;

  &.hover {
    background-color: #e2e2e2;
  }
}
