.reviews {
  display: grid;
  padding: 24px 12px;
  row-gap: 24px;
  border-top: 1px solid var(--gl_dividerBorder_primary);
  overflow: hidden;
  .title {
    font: var(--font-16-b);
    color: var(--gl_text_secondary_2);
    margin: 0;
  }
  .list {
    display: grid;
    overflow: auto;
    row-gap: 24px;

    .reviewCard {
      display: grid;
      row-gap: 12px;

      header {
        color: var(--gl_text_inverse);
        .dateTime {
          font: var(--font-14-b);
        }
        .restaurantName {
          font: var(--font-14-r);
        }
        .publishStatus {
          font: var(--font-14-r);
          color: var(--gl_text_secondary_disabled);
        }
      }

      .content {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        overflow: hidden;
      }
    }
  }

  .noList {
    height: 100%;
    display: flex;
    flex-direction: column;
    place-content: center;
    text-align: center;
    font: var(--font-14-r);
    line-height: 1.5em;
    white-space: pre-line;
    color: var(--gl_text_constant_secondary_disabled2);
  }
}
