@import "styles/mixins";

.guest-card-empty {
  height: 100%;

  --msg-font: var(--font-24-b);

  @media (max-width: 1366px) {
    --msg-font: var(--font-16-b);
  }

  display: flex;
  @include tablet {
    display: none;
  }
  flex-direction: column;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;

  .img.contact {
    @include icon-svg-color-size("contact", var(--icon-color, #bec5d1), 220px);
    margin-bottom: 70px;
  }

  p {
    font: var(--msg-font);
    color: var(--cadet-blue);
    text-align: center;
    max-width: 600px;
  }

  &.create-guest {
    p {
      padding-bottom: 40px;
      margin: 0px 100px;
      border-bottom: 1px solid #dde3ef;
    }

    button {
      margin-top: 40px;
    }
  }
}
