.bookingChatContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bookingCard {
  margin: 10px;
}

.chat {
  min-height: 0;
}
