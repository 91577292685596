@import "styles/mixins";

.phone {
  flex: 1;
  input:disabled {
    color: #ababab;
  }
}

.container {
  position: relative;
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: flex-end;
}

.clear {
  display: var(--book-right-arr);
  position: absolute;
  fill: #bec5d1;
  stroke: #bec5d1;
  right: 5px;
  top: calc(50% - 15px);
}

.edit {
  display: var(--book-right-arr);
  position: absolute;
  right: 6px;
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;

  background: var(--btn-primary, var(--dodger-blue));
  border-radius: 5px;
  color: white;

  padding: 0;

  mask: var(--src);
  -webkit-mask: var(--src);
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  mask-size: contain;
  -webkit-mask-size: contain;
}

.editNew {
  background-color: var(--columnButton_active_background);
  color: var(--gl_text_constant_primary2);
}

.guestButton {
  display: flex;
  width: 34px;
  height: 34px;
  background-color: var(--athens-gray);
  padding: 6px;
  box-shadow: 0 0 10px #9ea3ae;
  border-radius: 100%;
  background: #fff;
  flex: none;
  align-self: flex-end;
}

.guestIcon {
  background: transparent;
  color: var(--dodger-blue);
}

.guestIconNew {
  cursor: pointer;
  background: transparent;
  color: var(--dodger-blue);
  margin-right: 5px;
}

@include forDarkTheme {
  .guestButton {
    background-color: var(--06dp);
    box-shadow: none;
  }
}
