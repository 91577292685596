.twoElems {
  display: grid;
  grid-template-columns: 229px 31px;
  justify-content: space-between;
}

.input {
  border-color: var(--gl_dividerBorder_primary);
  color: var(--gl_text_secondary_disabled);
  cursor: not-allowed;
  -webkit-text-fill-color: var(--gl_text_secondary_disabled);
  -webkit-opacity: 1;
}

.placeSelect {
  display: grid;
  grid-template-columns: auto;
  gap: 8px;

  button {
    display: none;
  }

  &.showButton {
    grid-template-columns: auto 30px;
    button {
      display: grid;
    }
  }

  @media (min-width: 744px) and (max-width: 1185px) {
    grid-template-columns: 10fr 1fr;
    gap: 4px;

    button {
      display: grid;
    }
  }
}

.openScheme {
  svg {
    color: var(--columnButton_active_background);
  }

  &:disabled {
    svg {
      opacity: 0.7;
    }
  }
}
