.extraStep {
  display: grid;
  row-gap: 16px;
  align-content: flex-start;
  color: var(--gl_text_inverse);
}

.textareaContainer {
  grid-template-columns: 1fr min-content;
  row-gap: 4px;
  justify-items: flex-start;
  .textareaCounter {
    grid-area: auto;
    margin: 0;
  }
  .textarea {
    font: var(--font-12-r);
    grid-area: auto;
    grid-column: 1 / -1;
    min-height: 124px;
  }
}
