@import "styles/mixins";

.popup{
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(98, 121, 147, 0.4);
  z-index: 1000;
  padding: 12px;
}

.noPadding{
  padding: 0;
}

.noLeftPadding{
  padding-left: 0;
}
.noRightPadding{
  padding-right: 0;
}
.noTopPadding{
  padding-top: 0;
}
.noBottomPadding{
  padding-bottom: 0;
}

@include forDarkTheme{
  .popup{
    background-color: var(--06dp);
    color: var(--gray);
    box-shadow: none;
  }
}
