.header {
  grid-area: layout_header;
  display: grid;
  grid-template-columns: max-content 1fr min-content;
  background-color: var(--header_background);
  padding: 14px 12px;
  align-items: center;
  min-height: 60px;
  column-gap: 8px;
  margin: 0;
}

.title {
  color: var(--gl_text_constant_primary2);
  font: var(--font-16-b);
}
