@import "styles/mixins";

.calendarLoader,
.grid {
  grid-column: span 2;
  background: var(--gl_background_2);
}

.tableResource {
  display: grid;
  row-gap: 5px;

  .title {
    font: var(--font-12-b);
    color: var(--gl_text_inverse);
  }

  .capacity {
    font: var(--font-12-r);
    color: var(--gl_text_secondary_disabled);
  }
}

.modalGrid {
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: var(--gl_background_1);
  min-width: 1050px;
  border-radius: 3px;
  max-height: 90vh;
  overflow: hidden;

  @include tablet {
    min-width: auto;
  }
}

.slotLabel {
  display: grid;
  row-gap: 5px;

  .capacity {
    font: var(--font-12-r);
    color: var(--gl_text_secondary_disabled);
  }
}

.slotTime {
  font: var(--font-12-b);
  color: var(--gl_text_inverse);
}

.booked {
  display: grid;
  grid-auto-flow: column;
  column-gap: 5px;

  .bookedCount {
    color: var(--gl_icon_primary_1);
    font: var(--font-12-b);
  }
}

.bookingItem {
  height: 40px;
  background: var(--gridBookingCard_default_background);
  border: none;
  padding: 0;
  border-radius: 3px;
}

.bookingItemInner {
  container-type: inline-size;
  container-name: bookingItem;
  display: grid;
  width: 100%;
  justify-self: start;
  height: 40px;
  grid-auto-flow: column;
  //grid-auto-columns: min-content min-content minmax(0, max-content) min-content 1fr;
  grid-template-columns: min-content auto minmax(0, 1fr) repeat(2, min-content);
  align-items: center;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

  font: var(--font-12-b);
  text-align: center;

  &.isOverbooking {
    border-bottom: 2px inset var(--floorplanLegend_overbooking_background);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.contextMenuIcon {
  width: 24px;
  display: grid;
  place-items: center;

  --icon-color: var(--gl_icon_constant_secondary);
}

.bookingStatuses {
  justify-self: center;
  display: flex;
  margin-right: 8px;
  flex-direction: column;
  height: 24px;
  justify-items: center;
  justify-content: center;
  div {
    border-radius: 0 3px 3px 0;
    height: 100%;
    width: 4px;
  }
}

.persons,
.clientName {
  color: var(--gl_text_inverse);
  font: var(--font-12-b);
}

.persons,
.vip {
  justify-self: end;
}

.bookingTime,
.clientName {
  margin-right: 8px;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: start;
}

// Если бронь занимает 2 ячейки и меньше, то не отображать время брони для экономии места
@container bookingItem (max-width: 110px) {
  .bookingTime {
    display: none;
  }
}

// Если бронь занимает 1 ячейку, то не отображать время брони и количество клиентов для экономии места
@container bookingItem (max-width: 55px) {
  .bookingTime,
  .persons {
    display: none;
  }
}

.clientTags {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  margin-left: 4px;
}

.clientTag {
  width: 10px;
  height: 10px;
  border: 1px solid var(--16p);
  border-radius: 50%;
  margin-left: -4px;
}

.areaHeader {
  display: grid;
  row-gap: 4px;
  column-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  cursor: pointer;

  .caption {
    font: var(--font-12-r);
    color: var(--gl_text_inverse);
    grid-row: span 2;
  }

  .arrow {
    color: var(--gl_icon_constant_disabled);

    &.active {
      color: var(--gl_text_inverse);
    }
  }

  .downArrow {
    transform: rotate(180deg);
  }
}

.menu {
  width: 216px;
  border: 1px solid var(--gl_dividerBorder_primary_2);
  padding: 10px 20px;
}

.cancelContent {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
}

.noTablesOrShifts {
  --msg-font: var(--font-24-b);

  @media (max-width: 1366px) {
    --msg-font: var(--font-16-b);
  }

  height: 100%;
  font: var(--msg-font);
  color: var(--cadet-blue);
  display: grid;
  place-items: center;
}

.scheme,
.guests {
  min-width: 70vw;
  display: grid;
  min-height: 0;
  grid-template-rows: 1fr;
}
