.menu {
  width: 216px;
}

.cancel {
  color: var(--petite-orchid);
  &:disabled {
    border: none;
    background: none;
  }
}

.cancelContent {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
}

.cancelContentM {
  color: var(--gl_text_constant_danger);
  --line-color: currentColor;
}
