// отступы, grid-area и инициализация каунтера
.stepList {
  grid-area: content_col1/content_col1/content_col3/content_col3;
  background-color: var(--gl_background_1);
  padding: 16px 12px;
  counter-reset: step;
  border-right: 1px solid var(--gl_dividerBorder_primary);
  width: min(364px, 35dvw);
  min-width: min-content;

  .stepItem {
    .navLink {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 32px minmax(auto, 1fr);
      column-gap: 16px;
      cursor: pointer;

      .stepTitle {
        font: var(--font-15-b);
        color: var(--gl_text_secondary_disabled);
        margin-top: 4px;
        transition: color 0.3s;
      }

      .stepDescription {
        font: var(--font-14-r);
        text-wrap: balance;
        color: var(--gl_text_secondary_disabled);
        transition: color 0.3s;
        grid-column: 2 / 3;
        white-space: pre-wrap;
      }
    }

    // каунтер с круглой границей. стандартно серого цвета
    .navLink::before {
      counter-increment: step;
      content: counter(step);
      box-sizing: border-box;
      display: flex;
      width: 32px;
      place-content: center;
      align-items: center;
      aspect-ratio: 1;
      border-radius: 50vmax;
      border: 1px solid var(--gl_dividerBorder_primary);
      color: var(--gl_text_secondary_disabled);
      font: var(--font-14-r);
      grid-row: 1 / 2;
      transition:
        color 0.3s,
        background-color 0.3s,
        border-color 0.3s;
    }

    // после каждого каунтера, кроме последнего нарисовать серую вертикальную линию
    &:not(:last-of-type) .navLink::after {
      content: "";
      margin-block: 6px;
      grid-row: 2 / 3;
      justify-self: center;
      width: 1px;
      min-height: 36px;
      background-color: var(--gl_dividerBorder_primary);
      transition: background-color 0.3s;
    }

    // если текущий шаг выбран, то сделать заголовок ярче, раскрасить фон каунтера в accent, текст каунтера в text_inverse2
    .navLink.active {
      .stepTitle {
        color: var(--gl_text_inverse);
      }
      &::before {
        color: var(--gl_text_inverse2);
        background-color: var(--gl_background_accent);
        border-color: var(--gl_background_accent);
      }
    }

    // если шаг валиден и не выбран, то меняем каунтер на ✓, раскрашиваем в accent галочку, границу и линию после неё
    &.valid {
      .navLink:not(.active) {
        &::before {
          content: "✓";
          font: var(--font-26-r);
          color: var(--gl_background_accent);
          border-color: var(--gl_background_accent);
        }
        &::after {
          background-color: var(--gl_background_accent);
        }
      }
    }

    // если шаг инвалиден и не выбран, то меняем каунтер на !, раскрашиваем в danger восклицательный знак, границу и линию после неё
    &.invalid {
      .navLink:not(.active) {
        &::before {
          content: "!";
          font: var(--font-26-r);
          font-weight: 200;
          color: var(--gl_icon_constant_danger);
          border-color: var(--gl_icon_constant_danger);
        }
        &::after {
          background-color: var(--gl_icon_constant_danger);
        }
      }
      // если шаг инвалиден и выбран, то каунтер не меняем, раскрашиваем в danger фон, границу и линию после неё
      .navLink.active {
        &::before {
          border-color: var(--gl_icon_constant_danger);
          background-color: var(--gl_icon_constant_danger);
        }
        &::after {
          background-color: var(--gl_icon_constant_danger);
        }
      }
      //раскрашиваем описание инвалидного шага в danger
      .stepDescription {
        color: var(--gl_icon_constant_danger);
      }
    }
  }
}
