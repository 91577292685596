#root main#schedule-landing {
  --z-index-multi: 0;

  display: block;
  border-radius: 3px;
  box-shadow: var(--shadow-light);
  border: none;

  .block-content {
    max-width: 100%;
    padding: 25px var(--header-spacing) 0px 20px;
    display: grid;
    --header-spacing: 6px;
    grid-gap: var(--header-spacing);
     --col-width: calc((100vw - 2 * var(--header-spacing) - 2 * var(--pad) - var(--aside-width) - 20px) / 2);
    grid-template-columns: none;

    .block-table {
      --padding-table-block: 0px;
      --cell-width-tablet: 40px;
      padding: var(--padding-table-block);
      min-height: 0;
      display: flex;
      flex-direction: column;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 20px;
      }
    }
  }

  .control-block {
    display: flex;
    z-index: 5;

    button {
      width: 82px;
    }

    label.checkbox {
      margin-right: 10px;
      @media (max-width: 1366px) {
        span {
          font: var(--font-13-r);
        }
      }

      &:first-of-type {
        span {
          max-width: 115px;
        }
      }

      &:last-of-type {
        span {
          max-width: 125px;
        }
      }
    }
  }

  div.tables {
    width: 170px;
    margin-right: 10px;
  }

  .header-table {
    display: flex;
    padding: 0px 10px 15px 18px;
    justify-content: space-between;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #abb0be;

    @media (max-width: 1366px), (min-resolution: 120dpi) and (max-resolution: 140dpi) {
      font: var(--header-font-a);
      font-weight: 700;
    }

    .table-cell-total {
      min-width: 110px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 18px;
      margin-left: 5px;

      @media (min-width: 1920px), (max-width: 1366px), (min-resolution: 120dpi) and (max-resolution: 140dpi) {
        justify-content: center;
      }
    }

    .table-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 5px;
      box-sizing: border-box;
      text-align: center;
      min-width: 110px;
      @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
        min-width: var(--cell-width-tablet);
      }

      .img {
        &.red {
          @include icon-svg-color("ellipse", #e08d8d);
        }
        &.green {
          @include icon-svg-color("ellipse", #44de41);
        }
        &.orange {
          @include icon-svg-color("ellipse", #f1bb30);
        }
      }
    }
  }

  .rows {
    flex: 1 0;
    --header-table-height: 31px;

    &.large {
      --header-table-height: 47px;
    }

    overflow: auto;
    background-clip: content-box;
    padding-right: 6px;
    --header-spacing: 6px;

    .table-row {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      border: 1px solid #dde3ef;
      box-sizing: border-box;
      box-shadow: 0px 2px 3px rgba(81, 86, 95, 0.15);
      border-radius: 3px;
      height: 56px;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      @media (max-width: 1366px), (min-resolution: 120dpi) and (max-resolution: 140dpi) {
        font: var(--header-font-a);
      }

      &.active {
        box-shadow: 0px 2px 3px rgba(81, 86, 95, 0.15), 0px 4px 10px rgba(67, 109, 148, 0.5);
      }

      .table-cell-total {
        min-width: 110px;
        margin-left: 5px;
        background: #f1f5fe;

        @media (min-width: 1920px), (max-width: 1366px), (min-resolution: 120dpi) and (max-resolution: 140dpi) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .table-cell {
        display: flex;
        justify-content: center;
        padding: 18px 0px;
        margin: 0px 5px;
        box-sizing: border-box;
        background-color: #fff;
        min-width: 110px;
        @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
          min-width: var(--cell-width-tablet);
          margin: 0;
        }
        div {
          min-width: 33px;
          text-align: center;
          border-bottom: 1px dashed var(--dodger-blue);
          color: var(--dodger-blue);
        }

        .img {
          margin-left: 10px;
          @media only screen and (max-device-width: 1366px) and (orientation: portrait) {
            margin-left: 0;
          }
          &.red {
            @include icon-svg-color("ellipse", #e08d8d);
          }
          &.green {
            @include icon-svg-color("ellipse", #44de41);
          }
          &.orange {
            @include icon-svg-color("ellipse", #f1bb30);
          }
        }
      }
    }
  }
}
