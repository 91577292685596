.filterForm {
  display: grid;
  gap: 12px;

  .labeledSelect {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: var(--gl_text_inverse);
    font: var(--font-12-r);
  }
}

.resetButton {
  padding-inline: 8px;
  width: 40%;
  place-self: self-end;
  font: var(--font-14-b);
}
