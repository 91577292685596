.columnBar {
  display: grid;
  grid-auto-flow: column;
  column-gap: var(--columnBar-gap, 10px);
  align-content: center;
}

.parts {
  grid-template-columns: repeat(3, 1fr);
}

.left {
  grid-column: 1 / 2;
  justify-content: left;
}

.center {
  grid-column: 2 / 3;
  justify-content: center;
}

.right {
  grid-column: 3 / 4;
  justify-content: right;
}

.left,
.center,
.right {
  display: flex;
  gap: 10px;
  position: relative;
  align-items: center;
}
