.INITIAL {
    padding: 11px 12px;
    border-radius: 20px;
    cursor: pointer;
}

.DELETABLE {
    padding: 11px 6px 11px 12px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    gap: 4px;

    svg{
        width: 12px;
        height: 12px;
        stroke: #313131;
        fill: #313131;
    }
}