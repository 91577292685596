.reservationForm {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.hide {
  display: none;
}

.fullHeight {
  height: inherit;
}