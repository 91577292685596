.tableForm {
  display: grid;
  row-gap: 12px;
  align-content: start;
}

.label {
  font: var(--font-12-b);
  color: var(--gl_text_inverse);
  &::after {
    content: " *";
    font: var(--font-12-b);
    vertical-align: top;
    color: var(--gl_icon_constant_danger);
  }
}

.hallButton {
  display: flex;
  justify-items: center;
  align-content: center;
  color: var(--gl_icon_accent);
}

.hallTable {
  border: 1px solid var(--gl_dividerBorder_primary);
  column-gap: 4px;
  justify-content: space-between;
  padding: 12px;
  min-width: 145px;
  color: var(--gl_text_inverse);
  font: var(--font-14-b);
  white-space: nowrap;

  .capacity {
    color: var(--gl_text_secondary_disabled);
    --icon-color: var(--gl_icon_primary_1);
    font: var(--font-12-r);
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

input {
  &:not(:where(:checked, :disabled)) + .hallTable:not(:hover) {
    background-color: var(--gl_background_2);
    color: var(--gl_text_inverse);
  }
  &:checked + .hallTable {
    background-color: var(--gl_background_accent_2);
    border-color: var(--gl_dividerBorder_accent);
    color: var(--gl_text_inverse);
  }
}
