.tableModal {
  position: fixed;
  z-index: 100;
  inset: 0;
  display: flex;
  flex-direction:column;
}

.content {
  height: 100%;
  position: relative;
  background-color: var(--scheme-bg);

  > * {
    position: absolute;
    inset: 0;
  }
}

.placeSelector {
  width: 190px;
  margin: 0 24px;
}

.timelineToolbar {
  border-top: 1px solid var(--gl_dividerBorder_primary);
  background: var(--bottomBar_background_1);
  color: var(--gl_text_inverse);
  padding-block: 20px 10px;
}

.toolbarInner {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-inline: 30px;
  border-bottom: 1px solid var(--gl_dividerBorder_primary);

  .info {
    font: var(--font-14-r);
    color: var(--gl_text_inverse);
    display: flex;
    align-items: center;
    gap: 6px;

    .icon {
      color: var(--gl_icon_accent);
    }
  }
}