.dataSection {
  display: grid;
  row-gap: 24px;
}

.title {
  font: var(--font-16-b);
  color: var(--gl_text_secondary_2);
}

.dataList {
  display: grid;
  row-gap: 12px;

  .item {
    display: grid;
    align-content: center;
    row-gap: 4px;
    dt {
      font: var(--font-12-r);
      color: var(--gl_text_secondary_disabled);
    }
    dd {
      font: var(--font-14-b);
      color: var(--gl_text_inverse);
      button {
        cursor: copy;
        height: auto;
        max-width: 100%;
      }
    }
  }
}
