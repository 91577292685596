.timeSlotsList {
  overflow-y: auto;
  .timeSlots {
    row-gap: 4px;
    label {
      height: 48px;
      max-width: max-content;
    }
    .slotDivider {
      width: 100%;
      text-align: center;
      font: var(--font-12-r);
      color: var(--gl_text_secondary_2);
      margin-bottom: 4px;
    }
  }
}
