#phone-modal {
  display: block;
  position: absolute;
  right: 60px;
  top: 60px;
  background-color: white;
  box-shadow: 0 2px 3px rgba(48, 52, 58, 0.15), 0 4px 10px rgba(43, 65, 85, 0.5);
  border-radius: 3px;
  width: 540px;
  --h-container: 620px;
  max-height: var(--h-container);

  @media (max-width: 1366px) {
    --h-container: 518px;
    width: 400px;
  }

  .modal-header {
    background: var(--dodger-blue);
    padding: 0 var(--header-spacing);
    height: var(--main-block-top-section-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--periwinkle-gray);
    box-sizing: border-box;

    h2 {
      font: var(--main-block-top-section-font);
      white-space: nowrap;
      margin-right: 20px;
    }

    svg {
      cursor: pointer;
      path {
        fill: #fff;
      }
    }
  }

  .content {
    max-height: calc(var(--h-container) - var(--main-block-top-section-height) - 40px);
    padding: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    color: var(--mine-shaft);
  }
}
