@import "styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  position: relative;
}

.hall {
  background: var(--scheme-bg);
}

.schemeContainer {
  position: relative;
  display: flex;
  flex: 1 1;
  min-height: 0;
}

.controls {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.legend {
  cursor: pointer;
  display: block;
  color: #BEC5D1;
  height: auto;
  border-radius: 3px;
  border: 1px solid;
  background: #fff;
  @media print {
    display: none;
  }
}

.legend_active{
  background: var(--dodger-blue);
  color: #fff;
}

.bookingListButton{
  position: absolute;
  left: 12px;
  top: 12px;
  width: 41px;
  height: 41px;
  background: var(--legendButton_default_background);
  border: 1px solid var(--gl_dividerBorder_primary);
  color: var(--gl_icon_constant_secondary);
}


@include forDarkTheme {
  .legend{
    border:  none;
  }

  .legend:not(.legend_active) {
    background: var(--06dp);
    color: var(--silver-chalice);
  }
}
