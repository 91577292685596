@import "styles/mixins";

.listContainer{
  padding-bottom: 77px;
  position: relative;
}

.list {
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow: auto;
}

.bookingCard {
  flex: none;
}

.section {
  display: flex;
  flex-direction: column;
}

.sectionHeader {
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  font-weight: 700;
  color: #484f54;
  position: sticky;
  top: 0px;
  background: var(--wrf-white);
  box-shadow: 0px 2px 3px rgba(81, 86, 95, 0.15);
  z-index: 1;
}

@include forDarkTheme {
  .sectionHeader{
    background: var(--03dp);
    color: #fff;
  }
}

.sectionContent {
  padding: 10px 0;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.edit {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.editCard{
  padding: 10px;
}


@include forDarkTheme {
  .listContainer{
    background: var(--02dp);
  }

  .bookingCard{
    background: var(--02dp);
  }
}
