.userForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 12px;

  .spanAllColumns {
    grid-column: 1 / -1;
  }

  label {
    span,
    input {
      font: var(--font-12-r);
    }
  }

  .divider {
    border: 1px solid var(--gl_dividerBorder_primary_2);
    margin: 0;
  }

  .textButton {
    width: max-content;
    cursor: pointer;
    color: var(--textButton_default_text);
    font: var(--font-14-b);
    border-bottom: 1px dashed var(--textButton_default_text);
  }

  // Отображаем селект ролей вместе с горизонтальной линией только, если активна кнопка изменения роли, либо при создании нового пользователя
  // Селект ролей всегда отображаем вверху формы
  .roleSelectCheckbox {
    &:checked {
      & ~ .roleSelect {
        grid-row: 1;
        & + .divider {
          grid-row: 2;
        }
      }

      & + .textButton {
        display: none;
      }
    }
    &:not(:checked) {
      & ~ .roleSelect {
        display: none;
        & + .divider {
          display: none;
        }
      }
    }
  }

  .login:not(:where(:placeholder-shown, :disabled)) {
    text-transform: lowercase;
  }
}

.userModal {
  width: 464px;
  font: var(--font-16-b);
}

.conformationModal {
  .body {
    display: grid;
    column-gap: 16px;
    row-gap: 8px;
    grid-template-columns: 24px auto;
    margin: 0;
    padding: 16px;
    h2 {
      color: var(--gl_text_inverse);
      margin: 0;
      font: var(--font-16-b);
    }
    .conformationMessage {
      display: grid;
      row-gap: 8px;
      color: var(--gl_text_inverse);
      margin: 0;
      font: var(--font-14-r);
      white-space: pre-line;
    }
    .shortUserInfo {
      font: var(--font-14-b);
    }
    .irreversibleAction {
      font: var(--font-16-b);
      color: var(--gl_icon_constant_danger);
    }
    .icon {
      color: var(--gl_icon_constant_attention);
      grid-row: span 3 / span 3;
    }
    .actions {
      display: inline-flex;
      column-gap: 2px;
      place-self: flex-end;
    }
    .danger {
      color: var(--gl_icon_constant_danger);
    }
  }
}
