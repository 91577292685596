@import "styles/mixins";

.header {
  grid-area: layout_header;
  background: var(--header_background);
  height: var(--header-height);
  width: 100%;
  color: #fff;
  align-items: center;
  box-sizing: border-box;
  padding-inline: 12px;
}

.headerContent {
  grid-template-columns: 1fr auto 1.2fr;
  height: 100%;
}

.section {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
}

.logo,
.menuControl {
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  @include tablet {
    display: none;
  }
}

.menuControl {
  display: none;
  @include tablet {
    display: flex;
  }
}
