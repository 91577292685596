.shifts {
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;
  overflow: auto;
}

.header {
  display: grid;
  grid-auto-flow: column;
  height: 55px;
}

.leftBlock{
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  grid-auto-columns: min-content;
  padding: 12px;
}

.viewSwitcher {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-self: end;

  & .switcherIcon {
    cursor: pointer;
    border-right: 1px solid var(--select_default_background_2);
    padding: 0 5px;
    fill: var(--gl_icon_primary_1);
  }
  & .switcherIcon:last-child {
    border-right: none;
  }
  & .switcherIconActive{
    fill: var(--gl_icon_accent);
  }
}

.schedule {
  display: grid;
}

.event {
  display: grid;
}

.weekInputContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
}

.input {
  cursor: default;
  color: var(--gl_text_inverse);
  background: var(--dtpkr_background_1);
  border-top: 1px solid var(--gl_dividerBorder_primary);
  border-bottom: 1px solid var(--gl_dividerBorder_primary);
  display: grid;
  align-items: center;
  padding: 0 8px;
  font: var(--font-12-r);
}

.iconContainer {
  display: grid;
  place-items: center;
  width: 34px;
  cursor: pointer;
  background: var(--dtpkr_background_1);
  border: 1px solid var(--gl_dividerBorder_primary);

  &.prev {
    border-radius: 3px 0 0 3px;
  }

  &.next {
    border-radius: 0 3px 3px 0;
  }
}

.inputIcon {
  color: var(--gl_icon_constant_secondary);
}
