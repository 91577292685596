.navTabs {
  --gl_background_3: transparent;
  --gl_dividerBorder_primary_2: var(--gl_dividerBorder_primary);
  border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
  background-color: var(--gl_background_3);
  padding-inline: 14px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .addon {
    padding: 0 12px;
    display: flex;
    --icon-color: var(--gl_icon_primary_1);
  }

  .cross {
    cursor: pointer;
    --icon-color: var(--gl_icon_primary_1);
  }
}

.tabsContainer {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-block: 19px 12px;
  margin-bottom: 0;
  &:has(.tabTitle.hideOtherLinks) {
    .tabTitle:not(.hideOtherLinks) {
      display: none;
    }
  }
}

.tabTitle {
  margin-right: 32px;
  cursor: pointer;
  position: relative;
  color: var(--gl_text_constant_secondary_disabled2);
  display: flex;
  font: var(--font-16-r);
  gap: 10px;
  align-items: center;
  text-wrap: nowrap;
  transition: color 0.2s ease-in;

  &:not(.disabled):hover {
    color: var(--tabs_hover_content);
  }

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 6px;
    background-color: transparent;
    position: absolute;
    bottom: -15px;
    transition:
      0.3s ease-in width,
      0.3s ease-in background-color;
    box-sizing: initial;
  }
}

.active {
  color: var(--gl_text_inverse);
  font: var(--font-16-b);
  font-weight: 700;
  pointer-events: none;
  &:after {
    background-color: var(--tabs_active_content);
    width: 100%;
  }
}

.disabled {
  color: var(--tabs_disabled_content);
  cursor: not-allowed;
  opacity: 0.5;
  &:hover {
    color: var(--tabs_disabled_content);
  }
}

.hide {
  display: none;
}
