.radioGroup {
  --radio-button-width: 87px;
  --radio-button-column-gap: 4px;
  --radio-button-row-gap: var(--radio-button-column-gap);
  --radio-button-border-color: var(--gl_dividerBorder_primary);
  --radio-button-border-width: 1px;
  --radio-button-border-radius: 3px;
}

.buttons {
  display: flex;
  column-gap: var(--radio-button-column-gap);
  row-gap: var(--radio-button-row-gap);
  flex-wrap: wrap;
  align-content: flex-start;

  &.bordered {
    background-color: var(--radio-button-border-color);
    padding: var(--radio-button-border-width);
    gap: var(--radio-button-border-width);
    border-radius: var(--radio-button-border-radius);
  }
}

.label {
  grid-column: 1 / -1;
  margin-bottom: 4px;
  font: var(--font-12-b);
  color: var(--gl_text_inverse);
  &.required {
    &::after {
      content: " *";
      font: var(--font-12-b);
      vertical-align: top;
      color: var(--gl_icon_constant_danger);
    }
  }
}

.radioButton {
  display: flex;
  flex: 1;
  min-width: var(--radio-button-width);
  min-height: 31px;
  align-items: center;
  justify-content: center;
  color: var(--gl_text_inverse);
  background-color: var(--radioButton_default_background_1);
  font: var(--font-12-r);
  cursor: pointer;
  user-select: none;
  transition:
    background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;

  // варианты кнопок: "flat", "bordered", "compact"

  &.flat {
    border-radius: 3px;
    background-color: var(--radioButton_disabled_background);
  }

  &.bordered {
    border-radius: calc(
      var(--radio-button-border-radius) - var(--radio-button-border-width)
    );
  }

  /* &.bordered {
    --border-color: var(--gl_dividerBorder_primary);
    --border-width: 1px;
    --border-style: solid;

    border-block: var(--border-width) var(--border-style) var(--border-color);
    border-left: var(--border-width) var(--border-style) var(--border-color);
    &:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-right: var(--border-width) var(--border-style) var(--border-color);
    }
  } */
}

.radioButton:hover {
  background-color: var(--selectMenu_hover_background);
}

input:disabled + .radioButton {
  color: var(--gl_text_secondary_disabled);
  background-color: var(--radioButton_disabled_background);
  cursor: not-allowed;
}

input:checked + .radioButton {
  color: var(--gl_text_constant_primary2);
  background-color: var(--radioButton_active_background);
}

input:checked:disabled + .radioButton {
  opacity: 0.5;
}
