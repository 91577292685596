.roleCard {
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;
  grid-auto-flow: column;
  grid-template-columns: 28% 150px minmax(100px, 1fr);
  grid-auto-columns: 1fr;
  column-gap: 24px;
  font: var(--font-16-r);

  &.expandedRestaurants {
    grid-template-columns: 28% minmax(150px, max-content) minmax(100px, 1fr);
  }

  .userName {
    margin: 0;
  }

  .restaurantsButton {
    justify-content: flex-start;
    white-space: normal;
    height: auto;
    text-align: start;
    -webkit-user-select: text;
    user-select: text;
  }

  .restaurants {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    &.expandedRestaurants {
      word-break: normal;
      padding-block: 4px;
      display: inline-flex;
      flex-wrap: wrap;
    }
  }

  figure {
    --icon-color: var(--gl_icon_constant_primary);
  }

  aside {
    display: inline-flex;
    column-gap: 10px;
    justify-self: flex-end;
    .edit {
      color: var(--gl_icon_accent);
      align-content: center;
    }
    .delete {
      --icon-color: var(--gl_icon_constant_danger);
      align-content: center;
    }
  }
}

.noRoles {
  display: flex;
  padding-top: 32px;
  padding-inline: 10px;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  strong {
    margin: 0;
    font: var(--font-24-b);
    color: var(--gl_text_secondary_disabled);
  }
}
