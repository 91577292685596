.infiniteList {
  overflow-y: auto;
  grid-column: 1 / -1;

  .clientCard {
    border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
    display: grid;
    align-items: center;
    grid-template-columns: auto min-content;
    padding: 16px 12px;
    transition:
      background-color 0.3s,
      opacity 0.3s;
    &:hover:not(:has(.clientDetail:hover)),
    &:has(.active) {
      background: var(--guestsItemlist_hoverActive_background);
    }

    &:has(a.pending) {
      opacity: 0.5;
    }

    .clientInfo {
      grid-area: 1 / 1 / auto / span 2;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      align-items: flex-start;
      height: auto;

      & > * {
        display: inline-flex;
        align-items: center;
        color: var(--gl_text_inverse);
        font: var(--font-14-r);
      }

      .fullName {
        font: var(--font-14-b);
        column-gap: 8px;
        margin: 0;
      }

      .phone {
        column-gap: 4px;
        margin: 0;
      }
    }

    .clientDetail {
      grid-area: 1 / 2;
      path {
        fill: var(--gl_icon_constant_secondary);
      }
    }
  }

  .loadMoreButton {
    min-height: 50px;
  }
}

.tags {
  grid-row: 3;
  grid-column: span 3;
  gap: 3px;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: flex-start;
  overflow: hidden;
  gap: 12px;
  &.additionalPadding {
    padding-top: 12px;
  }
}
.search {
  padding-left: 12px;
}
.tagSelect {
  padding-right: 12px;
}

.listHeader {
  grid-column: 1/ -1;
}
