// Если родитель grid - указать area через --badge-area

.badge {
  padding-inline: 2px;
  grid-area: var(--badge-area);
  justify-self: flex-end;
  margin-right: 25%;
  height: 20px;
  min-width: 20px;
  width: max-content;
  border-radius: 20px;
  background-color: var(--badge_constant_background);
  align-content: center;
  text-align: center;
  @starting-style {
    transform: scale(0, 0);
    opacity: 0;
  }
  transform: scale(1, 1);
  opacity: 1;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;

  &[value="0"] {
    transform: scale(0, 0);
    opacity: 0;
  }
  &::after {
    content: attr(value);
    font: var(--font-12-b);
    color: var(--badge_constant_content);
  }
}
