@import 'styles/mixins';

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--gl_background_2);
}

.emptyNotes {
  display: flex;
  height: 93px;
  width: 251px;
  margin: auto;
  text-align: center;
  font: var(--font-16-b);
  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: var(--gl_text_constant_secondary_disabled2);
}

.notes {
  overflow-y: scroll;
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.note {
  display: flex;
  color: var(--gl_text_inverse);
  font: var(--font-14-r);
  padding: 16px 0px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background-color: var(--gl_background_2);
  border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
}

.avatar {
  width: 40px;
  height: 40px;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  .date {
    font: var(--font-14-b);
  }

  input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    display: none;
  }

  p {
    flex: 1;
    color: var(--gl_text_secondary_disabled);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
  }

  label {
    display: flex;
    padding: 4px 0;
    width: max-content;
    color: var(--gl_text_secondary_disabled);
    align-items: center;
    cursor: pointer;
    h2 {
      color: var(--gl_text_inverse);
    }
    svg {
      transition: all 0.3s;
    }
  }

  label::before {
    content: attr(data-showmore);
  }

  input:checked + p {
    -webkit-line-clamp: unset;
  }

  input:checked ~ label svg {
    transform: rotate(180deg);
  }

  input:checked ~ label::before {
    content: attr(data-showless);
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.action {
  --icon-color: var(--gl_icon_primary_1);
  color: var(--gl_icon_primary_1);
}

.divider {
  width: 1px;
  height: 18px;
  background-color: var(--gl_dividerBorder_primary_2);
}

.modal {
  width: 836px;

  @include tablet {
    width: 668px;
  }

  .modalBody {
    color: var(--gl_text_inverse);
    margin: 0;
    padding: 24px 16px;
  }
  .modalFooter {
    gap: 4px;
  }

  .noteForm {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 4px;
    align-self: stretch;

    label {
      display: flex;
      justify-content: space-between;
      width: 100%;

      h2 {
        font: var(--font-12-r);
        color: var(--gl_text_inverse);
      }

      span {
        font: var(--font-10-r);
        color: var(--gl_text_secondary_disabled);
      }
    }
    textarea {
      display: flex;
      padding: 8px;
      height: 280px;
      align-items: flex-start;
      align-self: stretch;
      resize: none;
      background-color: var(--select_default_background_1);
      border: 1px solid var(--gl_dividerBorder_primary);
      border-radius: 3px;
      font: var(--font-12-r);
      @include tablet {
        height: 224px;
      }
    }
  }
}
