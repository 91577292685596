#modal {
  .changes-params {
    background-color: #fff;
    color: #525252;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px rgba(48, 52, 58, 0.15), 0px 4px 10px rgba(43, 65, 85, 0.5);
    border-radius: 3px;

    .top-block-A {
      color: #000;
      height: 72px;

      h2 {
        font-size: 22px;
        line-height: 26px;
      }

      img {
        cursor: pointer;
      }
    }

    .content {
      overflow: auto;

      .separ {
        border-left: 1px solid #DDE3EF;
        height: 100%;
        margin: 0px 20px;
        min-height:100%;
        height: auto;
      }

      .definition {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        align-items: center;
        width: calc(35% - 14px);
        max-width: calc(35% - 14px);
        margin-right: 0px;
        background-color: whitesmoke;

        .tags .tag {
          background-color: #BEC5D1;
          padding: 0px 9px;
          margin: 0px;
        }

        .icon {
          width: 37px;
          margin-right: 4px;
          margin-left: -6px;
          display: flex;
          justify-content: center;
        }
      }

      .new, .old {
        height: 100%;
        display: flex;
        align-items: center;
        width: calc(32.5% - 14px);
        max-width: calc(32.5% - 14px);
        box-sizing: border-box;

        &.numbers {
          cursor: pointer;
          position: relative;
          max-height: 61px;
        }

        &:hover {
          .tooltip {
            visibility: visible;
            opacity: 1;
          }
        }
    
        .tooltip {
          top: 20px;
          line-height: 15px;
          max-width: 170px;

          &::after {
            bottom: -4px;
          }
        }
      }

      .new {
        border-left: 1px solid #DDE3EF;
      }

      .table-header {
        width: 740px;
        display: flex;
        align-items: flex-start;
        font-size: 18px;
        line-height: 21px;
        font-weight: bold;
        color: #484F54;

        .definition, .new, .old {
          align-items: flex-start;
        }

        .definition {
          padding: 20px 0px 20px 20px;
        }

        .new {
          padding: 0px 0px 20px 20px;
          margin-top: 20px;
        }

        .old {
          padding: 0px 0px 20px 0px;
          margin-top: 20px;
        }

        .separ {
          margin-top: 20px;
          height: 41px;
          min-height: initial;
        }
      }

      .table-body {
        width: 740px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        max-height: 510px;

        .table-row {
          display: flex;
          height: 40px;

          &.tags-only {
            min-height: 40px;
            height: auto;
            >div {
              min-height: inherit;
            }
            .definition {
              height: auto;
              align-items: center;
            }
            &:last-child {
              height: auto;
              .definition, .new, .old {
                height: auto;
              }
            }
          }

          &.numbers {
            max-height: 61px;
          }
          
          &.comment:last-child {
            .definition, .new, .old {
              height: auto;
            }
          }

          &:last-child {
            height: auto;
            .definition {
              padding-bottom: 20px;
              height: 61px;
            }
            .new, .old {
              margin-bottom: 20px;
              height: 41px;
            }
            .separ {
              min-height: initial;
              margin-bottom: 20px;
            }
          }

          .definition {
            padding-left: 20px;
            background-color: whitesmoke;
          }

          .new {
            box-sizing: border-box;
            padding-left: 20px;
          }
        }
      }

      &.one-column {
        .table-header, .table-body {
          width: 600px;
        }

        .definition {
          width: calc(50% - 20px);
          max-width: calc(50% - 20px);
        }

        .new {
          width: calc(50% - 20px);
          max-width: calc(50% - 20px);
          padding-left: 20px;
          border-left: 1px solid #DDE3EF;
        }
      }
    }
  }
}