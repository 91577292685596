.dataBlock {
  padding: 24px 12px;
  border-top: 1px solid #D9D9D9;
}

.item{
  margin-bottom: 12px;
}

.row {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  @extend .item
}

.checkbox {
  margin-top: 20px;
}