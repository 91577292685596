.accessRuleHeader {
    border: 1px solid var(--gl_dividerBorder_primary);
    :first-child {
        width: 100%;
    }

    .header {
        display: flex;
        align-items: center;
        font: var(--font-20-b);
        justify-content: space-between;
        padding-right: 24px;

        .restInfo {
            display: flex;
            gap: 31px;
            align-items: center;

            button {
                color: var(--gl_text_constant_primary2);
                padding: 2.5px 10px;
                font: var(--font-12-b);
                border-radius: 20px;

                &.active {
                    background-color: var(--tags_constant_background_2);
                }

                &.inactive {
                    background-color: #A0A2A8;
                }
            }

            .open {
                font: var(--font-14-b);
                color: var(--gl_text_accent);
                border-bottom: 1px dashed var(--gl_text_accent);
                padding-bottom: 5px;
            }
        }


    }
}

.icon {
    color: var(--gl_icon_inverse);
}

.rules {
    padding: 16px 18px;
    border-spacing: 0 4px;
    border-collapse: separate;
    width: 100%;

    tr {
        box-shadow: 0px 2px 3px rgba(81, 86, 95, 0.15);
        border-radius: 3px;
    }

    td {
        width: 25%;
        padding: 15px 12px;
        text-align: center;
        font: var(--font-16-r);
        color: var(--gl_text_inverse);
        border-top: 1px solid var(--gl_dividerBorder_primary_2);
        border-bottom: 1px solid var(--gl_dividerBorder_primary_2);
        background-color: var(--gl_background_2);

        &:nth-child(4){
            @media (min-width: 744px) and (max-width: 1185px) {
                display: none;
            }
        }

        svg {
            height: 17px;
            width: 17px;
            color: var(--gl_icon_primary_1);
            margin-right: 10px;

            path {
                height: 100%;
                width: 100%;
            }
        }

        span {
            text-transform: capitalize;
            font-weight: 400;
        }

        &.editIcon {
            svg {

                width: 24px;
                height: 24px;
                stroke: var(--columnButton_active_background);
                fill: var(--columnButton_active_background) !important;
                color: var(--columnButton_active_background);
            }
        }
    }
}