.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.modal {
  max-height: 100%;
  height: 100%;
  background-color: var(--gl_background_2);
  width: 400px;
  z-index: 2;
}

.modalContent {
  flex-grow: 1;
  overflow-y: scroll;
}

.modalContentAccess {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px;
  margin: 0px;
}

.modalFooter {
  justify-content: space-between;
}

.modalPortal {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;

  &:empty {
    display: none;
  }
}