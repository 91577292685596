.figure {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 6px;
  margin: 0;
  & > svg {
    min-width: max-content;
  }
}
