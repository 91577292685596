@import "styles/mixins";

button {
  &.primary,
  &.secondary,
  &.dangerous,
  &.light {
    padding: 0 13px;
    border-radius: 5px;
    color: white;
    font: var(--action-button-font);
  }
}

button {
  &.primary {
    background-color: var(--btn-primary, var(--dodger-blue));
  }
  &.secondary {
    background-color: var(--btn-secondary, var(--outer-space));
  }
  &.dangerous {
    background-color: var(--btn-dangerous, #ec4747);
  }
  &.light {
    background-color: var(--btn-light, var(--dove-gray));
  }
}

.control-group {
  background: #fff;
  box-sizing: border-box;
  height: var(--height-a);
  grid-column: span 2;
  display: flex;

  @media (max-width: 1366px) {
    font-size: var(--font-size-tablet);
  }

  .left,
  .right {
    width: 50%;
    display: flex;
    padding-right: 4px;
    > button {
      height: 100%;
      color: var(--dove-gray);
      padding: 0 10px;
      border-radius: 3px;
      border: 1px solid var(--botticelli);
      width: 100%;
      font: var(--font-12-r);

      &.active {
        background-color: var(--columnButton_active_background);
        font-weight: bold;
        color: var(--gl_text_constant_primary2);
      }
      &:disabled {
        background-color: var(--disabled);
        color: var(--silver-chalice);
        opacity: 1;
      }
    }

    .suffix {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      left: -1.5em;
    }
    .suffix {
      font: var(--font-16-r);
      color: var(--silver-chalice);
    }
  }

  .left{
    @media (max-width: 1024px) {
      width: 30%;
    }
  }
}

.wrapper-select {
  display: block;
  position: relative;
  min-height: var(--height-a);
  height: auto;
  width: 100%;

  div[class*="ValueContainer"] {
    height: calc(var(--height-a) - 2px);
  }
}

div[class*="count"] {
  --select-list-size: 4;
}

@for $i from 0 through 3 {
  div[class$="count#{$i}"] {
    @if $i == 0 {
      --select-list-size: 1;
    } @else {
      --select-list-size: #{$i};
    }
  }
}

div[class*="changed-size"][class*="count"] {
  --select-list-size: 9;
}

@for $i from 0 through 8 {
  div[class*="changed-size"][class$="count#{$i}"] {
    @if $i == 0 {
      --select-list-size: 1;
    } @else {
      --select-list-size: #{$i};
    }
  }
}

div[class*="pcexqc-container"] {
  position: absolute;
}

div[class*="pcexqc-container"].select-tags-top {
  position: relative;
}

div[class*="css-151xaom-placeholder"] {
  margin-left: 4px;
}

.css-14ojxsb-control {
  background-color: var(--disabled) !important;
}
.css-ue83k8-singleValue {
  color: var(--silver-chalice) !important;
}

.select-tags-top {
  width: 100%;
  height: 100%;
  border-radius: 3px 3px 3px 3px;
  border: var(--border-2-solid-botticelli);
  font: var(--font-14-r);
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: var(--height-a);
  box-sizing: border-box;

  div {
    cursor: pointer;
  }

  &.disabled {
    background-color: var(--disabled);
    color: var(--silver-chalice);
  }

  --control-vert-padding: 0;

  &.multiple-tags {
    --control-vert-padding: 5px;
    height: auto;
  }

  > div[class$="control"] {
    width: 100%;
    border: none;
    box-shadow: none;
    padding: var(--input-padding);
    // padding-top: var(--control-vert-padding);
    // padding-bottom: var(--control-vert-padding);
    box-sizing: border-box;
    height: auto;
    min-height: calc(var(--height-a) - var(--thin-border, 4px));
    border-color: transparent;

    div[class$="placeholder"] {
      color: var(--silver-chalice);
      font: var(--input-font);
      @media (max-width: 1366px) {
        font-size: var(--font-size-tablet);
      }
    }

    input[type="text"] {
      height: 100%;
    }

    div[class$="indicatorContainer"] {
      padding: 0;
      cursor: pointer;
    }

    div[class$="singleValue"] {
      color: var(--tundora);
      padding: 0;
      margin: 0;
      & + div {
        padding: 0;
        margin: 0;
      }
    }

    div[class$="multiValue"] {
      border-radius: 20px;
      margin: 2px 2px 3px;
      padding: 0 15px;
      div {
        font: var(--font-14-r);
        color: white;
      }
      div:last-child:hover {
        background-color: inherit;
        color: red;
      }
    }

    > div {
      height: inherit;
      padding: 0;
      margin: 0;
    }
  }

  > div[class$="menu"] {
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    background-color: white;
    z-index: -1;

    top: 0;
    left: -2px;
    transition: top 0.3s;

    > div {
      padding: 0;
      > div {
        color: var(--tundora);
        min-height: 35px;
        &.css-9gakcf-option {
          background-color: var(--selago);
        }
        &:hover {
          background-color: var(--selago);
        }
      }
    }
  }
}

.css-14ojxsb-control {
  background-color: var(--disabled) !important;
}
.css-ue83k8-singleValue {
  color: var(--silver-chalice) !important;
}
.css-1hb7zxy-IndicatorsContainer {
  > :last-child svg {
    -webkit-transition: -webkit-transform var(--animation-duration);
    transition: -webkit-transform var(--animation-duration);
    -o-transition: transform var(--animation-duration);
    transition: transform var(--animation-duration);
    transition: transform var(--animation-duration), -webkit-transform var(--animation-duration);
  }
}

.select-tags-top.opened {
  z-index: 10;
  border-radius: 0 0 3px 3px;
  box-shadow: 4px 4px 6px -1px rgba(173, 152, 152, 0.15), -4px 4px 6px -1px rgba(173, 152, 152, 0.15);
  overflow: visible;

  .css-1hb7zxy-IndicatorsContainer {
    > :last-child svg {
      transform: rotate(-180deg);
    }
  }

  > div[class$="menu"] {
    overflow: hidden;
    box-sizing: content-box;
    border-radius: 3px 3px 3px 3px;
    border: var(--border-2-solid-botticelli);
    border-bottom: 2px solid transparent;
    box-shadow: 4px -3px 6px -1px rgba(173, 152, 152, 0.15), -4px -3px 6px -1px rgba(173, 152, 152, 0.15);
    top: calc(-1 * var(--select-list-size) * 35px);

    > div {
      overflow-y: auto;
      max-height: calc(var(--select-list-size) * 35px);

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--alto);
      }

      > div[class*="xo7z33"] {
        background-color: var(--selago);
      }
    }
  }
}


.select,
.select-tags-bottom {
  background-color: white;
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  border-radius: 3px;
  border: var(--border-2-solid-botticelli);
  font: var(--font-14-r);
  overflow: hidden;
  height: var(--height-a);
  transition: min-height var(--animation-duration);

  &.css-2b097c-container {
    position: absolute;
  }

  div {
    cursor: pointer;
  }

  &.disabled {
    background-color: var(--disabled);
    color: var(--silver-chalice);
  }

  --control-vert-padding: 0;

  &.multiple-tags {
    --control-vert-padding: 5px;
  }

  > div[class$="control"] {
    border: none;
    overflow: hidden;
    box-shadow: none;
    min-height: unset;
    height: calc(var(--height-a) - 2px);
    box-sizing: border-box;
    padding: var(--input-padding);
    padding-top: var(--control-vert-padding);
    padding-bottom: var(--control-vert-padding);

    @media (max-width: 1366px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    div[class$="placeholder"] {
      color: var(--silver-chalice);
      font: var(--input-font);
      @media (max-width: 1366px) {
        font-size: var(--font-size-tablet);
      }
    }

    input[type="text"] {
      height: 100%;
    }

    div[class$="indicatorContainer"] {
      padding: 0;
      cursor: pointer;
    }

    div[class$="singleValue"] {
      padding: 0;
      margin: 0;
      & + div {
        padding: 0;
        margin: 0;
      }
    }

    div[class$="multiValue"] {
      border-radius: 20px;
      margin: 2px 2px 3px;
      padding: 0 15px;
      div {
        font: var(--font-14-r);
        color: white;
      }
      div:last-child:hover {
        background-color: inherit;
        color: red;
      }
    }

    > div {
      padding: 0;
      margin: 0;
    }
  }

  > div[class$="menu"] {
    position: absolute;
    top: calc(var(--height-a) - 3px);
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    max-height: 0;
    transition: max-height var(--animation-duration);

    > div {
      padding: 0;
      max-height: calc(var(--select-list-size) * 35px);
      overflow-y: auto;
      > div {
        color: var(--tundora);
        min-height: 35px;

        &[class*="css-9gakcf"],
        &:hover {
          background-color: var(--selago);
        }
      }
    }
  }
}

.source-for-1366 {
  div[class$="control"] {
    min-height: 38px;
  }
}

.select.opened,
.select-tags-bottom.opened {
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

  .css-1hb7zxy-IndicatorsContainer {
    > :last-child svg {
      transform: rotate(-180deg);
    }
  }

  > div[class$="menu"] {
    box-shadow: none;
    max-height: calc(var(--select-list-size) * 35px);
    margin: 0;

    > div {
      max-height: calc(var(--select-list-size) * 35px);

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--alto);
      }
    }
  }
}

.loyalty-bar {
  border-radius: 1px;
  width: 16px;
  height: 8px;
  overflow: hidden;
  background-color: var(--alto);
  position: relative;

  &::before {
    content: " ";
    position: absolute;
    height: 8px;
    width: var(--bar-width);
    background-color: var(--bar-color);
  }

  &.zero,
  &.null {
    --bar-width: 0;
  }

  &.low {
    --bar-width: 20%;
    --bar-color: var(--petite-orchid);
  }

  &.middle {
    --bar-width: 50%;
    --bar-color: var(--saffron);
  }

  &.high {
    --bar-width: 100%;
    --bar-color: var(--pastel-green);
  }
}

.tags {
  display: flex;
  align-items: center;
  flex-basis: var(--tags-flex-basis);
  gap: 2px;
  flex-wrap: wrap;

  .tag {
    height: 20px;
    padding: 0 15px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    line-height: normal;
    font-style: normal;
    margin: 2px 10px 2px 0;
    white-space: nowrap;
  }

  .orange {
    background-color: var(--orange);
  }

  .green {
    background-color: var(--green);
  }

  .blue {
    background-color: var(--blue);
  }

  .violet {
    background-color: var(--violet);
  }
}

.titled-block {
  h3 {
    white-space: nowrap;
  }
}

.switchable-sections {
  section {
    --badge-heght: 64px;
    --badge-font: var(--font-18-b);

    --collapse-button-bgc: none;
    --collapse-button-color: var(--icon-color, var(--heather));
    --collapse-button-rot: rotate(270deg);

    --card-content-height: 0;
    --card-content-overflow: hidden;
    --card-content-animation: none;

    --cards-block-margin: 0;
    --card-ends-marging: var(--header-spacing);

    &.active {
      --collapse-button-bgc: var(--periwinkle-gray);
      --collapse-button-color: var(--dodger-blue);
      --collapse-button-rot: rotate(90deg);

      --card-content-height: calc(
        var(--normalized-block-height) - var(--main-block-top-section-height) - 3 * var(--badge-heght)
      );
      --card-content-animation: empty-animation var(--animation-duration);
    }

    &.active .cards.opened {
      --card-content-overflow: auto;
    }

    @media (max-width: 1366px) {
      --badge-font: var(--font-14-b);
      --badge-heght: 50px;

      &.active .cards {
        --cards-block-margin: 0;
      }
    }

    overflow: hidden;

    .badge {
      height: var(--badge-heght);
      align-items: center;
      padding: var(--header-spacing);
      cursor: pointer;

      display: grid;
      grid-template-columns: auto min-content min-content;
      grid-gap: 13px 7px;
      color: var(--abbey);
      font: var(--badge-font);

      .info {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }

        span {
          font-weight: 500;
        }
      }

      .loyalty-bar {
        justify-self: center;
      }

      .collapse {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transform: var(--collapse-button-rot);
        transition: all var(--animation-duration);
        background-color: var(--collapse-button-bgc);

        --ico-color: var(--collapse-button-color);
      }
    }

    .cards {
      margin: var(--cards-block-margin);
      padding: 0 0 0 var(--header-spacing);
      display: grid;
      grid-auto-rows: min-content;
      grid-row-gap: 15px;
      height: var(--card-content-height);
      transition: height var(--animation-duration);
      box-sizing: border-box;
      overflow: var(--card-content-overflow);
      animation: var(--card-content-animation);

      > :first-child {
        margin-top: var(--card-ends-marging);
      }

      > :last-child {
        margin-bottom: var(--card-ends-marging);
      }
    }

    .content {
      transition: height var(--animation-duration);
    }
  }
}

.tooltip {
  color: #fff;
  text-align: center;
  opacity: 0;
  padding: 5px 10px;
  -webkit-transition: visibility 0s, opacity 0.3s;
  -o-transition: visibility 0s, opacity 0.3s;
  transition: visibility 0s, opacity 0.3s;
  position: absolute;
  background: #3a3a3a;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  visibility: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: #3a3a3a;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -webkit-box-shadow: 5px -5px 10px -5px rgba(98, 121, 147, 0.4);
    box-shadow: 5px -5px 10px -5px rgba(98, 121, 147, 0.4);
  }
}

@include forDarkTheme{
  .control-group{
    background-color: var(--01dp);
    .left > button{
      border-color: var(--06dp);
    }
    .left > button.disabled{
      background-color: var(--03dp);
    }
  }

  .control-group .left > button:disabled, .control-group .right > button:disabled{
    background-color: var(--06dp);
  }



  .select-tags-bottom, .select-tags-top, .select {
    border-color: var(--06dp);
    background-color: var(--03dp);

    div[class$="singleValue"],div[class$="multiValue"] {
      color: var(--wrf-white);
      div[class$="placeholder"]{
        color: var(--wrf-white);
      }
    }

    > div[class$="control"] {
      background-color: var(--03dp);
      color: var(--wrf-white);
    }
  }

  #booking section .badge{
    color: var(--wrf-white);
  }

  #booking section .badge .info div svg path{
    fill: var(--wrf-white) !important;
  }

  #booking section .badge .collapse{
    margin: 0;
    --ico-color: var(--wrf-white);
    background-color: var(--12dp);
  }

}
