@import "styles/mixins.scss";

.edit {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
}

.editCard {
  padding: 10px;
}

.closeDetails {
  cursor: pointer;
}

