// write styles here
@import "./common/common";
@import "./common/checkbox";
@import "./common/components";

@import "./components/root/root";

@import "./components/registration/registration";

@import "./containers/Auth/auth";

@import "./components/booking/booking";

@import "./components/guests/guests";
@import "./components/guest-card/guest-card";
@import "./components/schedule-landing/schedule-landing";

@import "./components/LegacyModal/modal";
@import "./components/modals/changes-params/changes-params";
@import "./components/modals/change-hostess/change-hostess";
@import "./components//modals//edit-status/edit-status";

@import "./components/settings/settings";

body {
  overflow: hidden;
}

@include forDarkTheme {
  background: var(--page-bg);
}

.rc-dropdown-menu-item {
  cursor: pointer;
}

// SELECT
:root {
  --menu-font: var(--font-14-r);
  @media (max-width: 1366px) {
    --menu-font: var(--font-12-r);
  }
}
