.halls {
  display: grid;
  row-gap: 12px;

  .hallGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 142px), 1fr));
    gap: 4px;

    .hallTitle {
      grid-column: 1 / -1;
      margin-block-end: 4px;
      color: var(--gl_text_secondary_2);
      font: var(--font-12-r);
      text-align: center;
    }
  }
}

.noTables {
  text-align: center;
  padding: 40px;
  color: var(--gl_text_secondary_disabled);
  font: var(--font-14-r);
}
