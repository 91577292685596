@import "styles/mixins";

.main {
  height: 100%;
  background-color: var(--catskill-white);
  box-sizing: border-box;

  display: grid;
  grid-gap: var(--header-spacing);
  grid-template-areas: "booking registration";
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @include tablet {
    grid-template-columns: 1fr;
    grid-template-areas: none;

    > * {
      box-shadow: 0px 2px 3px rgba(180, 199, 236, 0.35);
      border-radius: 3px;
    }
  }
}

.dialogs {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px;

  @include tablet {
    grid-template-columns: 1fr;
  }
}