.content {
  background-color: var(--gl_background_2);
  display: grid;
  overflow-y: auto;
  grid-template-rows: max-content max-content 1fr;
  align-content: flex-start;
  padding: 16px;

  details .legend {
    display: flex;

    border-radius: 3px;
    justify-content: space-between;
    padding-block: 8px;
    height: auto;
    background-color: var(--gl_background_gray_1);
    color: var(--gl_text_secondary_disabled);
    border: none;
    box-shadow: none;
    .legendTitle {
      font: var(--font-14-r);
    }
  }

  .legendContent {
    background-color: var(--gl_background_gray_1);
  }

  details[open] {
    .legend {
      box-shadow: none;
    }

    & + .legendContent {
      max-height: 300px;
    }
  }

  .variables {
    row-gap: 0;
    padding-top: 12px;

    .variable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: repeating-linear-gradient(
        90deg,
        transparent,
        transparent 2px,
        var(--gl_dividerBorder_primary_2) 2px,
        var(--gl_dividerBorder_primary_2) 3px
      );
      background-position: center;
      background-size: 100% 1px;
      background-repeat: no-repeat;

      dt {
        padding-right: 12px;
        background-color: var(--gl_background_gray_1);
        font: var(--font-14-r);
        color: var(--gl_text_inverse);
      }

      dd {
        padding-left: 12px;
        background-color: var(--gl_background_gray_1);
        color: var(--gl_text_secondary_disabled);
        font: var(--font-14-r);
      }
    }
  }

  .form {
    padding-top: 12px;
    display: grid;
    grid-template-rows: max-content max-content;
    row-gap: 12px;
    height: 100%;
    grid-auto-rows: max-content;

    .messageField {
      display: grid;
      .messageFieldHeader {
        display: inline-grid;
        grid-template-columns: 1fr max-content;
        align-items: center;
        padding-block: 8px;
        .label {
          font: var(--font-14-r);  
          color: var(--gl_text_secondary_disabled);
        }
      }
    }
  }
  
}
.footer {
    background-color: var(--bottomBar_background_2);
    display: flex;
    justify-content: space-between;
    padding: 24px 12px;
  }