.tagsModal {
    position: fixed;
    z-index: 100;
    inset: 0;
    display: flex;
    flex-direction:column;
}

.headerInputs {
    display: flex;
    align-items: center;
    margin-inline: 32px;
    gap: 12px;
    overflow: hidden;

    .search {
        width: 70%;
    }

    .groupFilter {
        width: 30%;
    }
}

.content {
    height: 100%;
    position: relative;
    background-color: var(--gl_background_1);
    overflow: auto;

    .groupHeader {
        height: 50px;

        .groupHeaderTitle {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
    
            .groupHeaderStats {
                color: var(--gl_text_secondary_disabled);
            }
        }
    }
    .groupContent {
        padding: 12px 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
}

.footer {
    height: 79px;
    background: var(--bottomBar_background_2);
    padding-inline: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mainButtons {
        display: flex;
        gap: 12px;
    }
}