.clientInfoStep {
  display: grid;
  overflow-x: hidden;
  transition: grid-template-columns 0.5s;

  & > * {
    transition: opacity 0.3s ease-out;
  }

  &.showClientForm {
    grid-template-columns: 0 100%;
    & > :first-child {
      opacity: 0;
    }
    & > :last-child {
      opacity: 1;
    }
  }

  &.showClientList {
    grid-template-columns: 100% 0%;
    & > :first-child {
      opacity: 1;
    }
    & > :last-child {
      opacity: 0;
    }
  }
}

.showFormButtons {
  display: flex;
  column-gap: 4px;
  li {
    display: contents;

    .showClientFormButton {
      flex: 1;
      color: var(--gl_text_inverse);
    }
  }
}

.clientList {
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content 1fr;
  & > * {
    padding: 0;
  }
}

.backButton {
  display: inline-flex;
  column-gap: 8px;
  color: var(--gl_icon_constant_secondary);
  font: var(--font-14-r);
  font-weight: 500;
  &::before {
    content: "";
    display: block;
    inline-size: 9.5px;
    aspect-ratio: 1;
    border-bottom: 2px solid var(--gl_icon_constant_secondary);
    border-left: 2px solid var(--gl_icon_constant_secondary);
    transform: rotate(45deg);
  }
}

.createClientForm {
  display: grid;
  column-gap: 12px;
  row-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 172px), 1fr));

  .phone {
    grid-column: 1/-1;
  }
}
